import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export type SideNavColors = "primary"
	| "secondary"
	| "info"
	| "success"
	| "warning"
	| "error"
	| "light"
	| "dark";

type LayoutTypes = "dashboard" | "page";

interface MuiSettingsState {
	miniSidenav: boolean;
	transparentSidenav: boolean;
	whiteSidenav: boolean;
	sidenavColor: SideNavColors;
	transparentNavbar: boolean;
	fixedNavbar: boolean;
	openConfigurator: boolean;
	layout: LayoutTypes;
	darkMode: boolean;
}

const initialState: MuiSettingsState = {
	miniSidenav: false,
	transparentSidenav: false,
	whiteSidenav: false,
	sidenavColor: "info",
	transparentNavbar: true,
	fixedNavbar: true,
	openConfigurator: false,
	layout: "dashboard",
	darkMode: false,
}


const localStorageState = () => {
	try {
		return JSON.parse(localStorage.getItem('muiSettings'));
	} catch (e) {
		return undefined;
	}
}


export const muiSettingsSlice = createSlice({
	name: 'muiSettings',
	initialState: localStorageState() ?? initialState,
	reducers: {
		setMiniSideNav: (state, action: PayloadAction<boolean>) => {
			state.miniSidenav = action.payload;
		},
		setTransparentSidenav: (state, action: PayloadAction<boolean>) => {
			state.transparentSidenav = action.payload;
		},
		setWhiteSidenav: (state, action: PayloadAction<boolean>) => {
			state.whiteSidenav = action.payload;
		},
		setSidenavColor: (state, action: PayloadAction<SideNavColors>) => {
			state.sidenavColor = action.payload;
		},
		setTransparentNavbar: (state, action: PayloadAction<boolean>) => {
			state.transparentNavbar = action.payload;
		},
		setFixedNavbar: (state, action: PayloadAction<boolean>) => {
			state.fixedNavbar = action.payload;
		},
		setOpenConfigurator: (state, action: PayloadAction<boolean>) => {
			state.openConfigurator = action.payload;
		},
		setLayout: (state, action: PayloadAction<LayoutTypes>) => {
			state.layout = action.payload;
		},
		setDarkMode: (state, action: PayloadAction<boolean>) => {
			state.darkMode = action.payload;
		},
	}
});


export const {
	setMiniSideNav, setTransparentSidenav, setWhiteSidenav, setSidenavColor,
	setTransparentNavbar, setFixedNavbar, setOpenConfigurator, setLayout, setDarkMode
} = muiSettingsSlice.actions;

export const selectMuiState = (state: RootState) => state.muiSettings;

export default muiSettingsSlice.reducer;

