/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "./BasicLayout/layout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import GoogleButton from "react-google-button";
import { useGetGoogleAuthUrlLazyQuery, useGetGoogleAuthUrlQuery } from "../../../_generated/graphql";

function Basic(): JSX.Element {
	const location = useLocation();
	const { data, error, loading, called } = useGetGoogleAuthUrlQuery({
		fetchPolicy: "cache-first",

	});
	// const [ refetch, { data, error, loading, called } ] = useGetGoogleAuthUrlLazyQuery({});

	useEffect(() => {
		const getUrl = async () => {
		}
	}, [])

	if (location.state) {
		if (location.state.initialUrl.pathname !== '/') {
			localStorage.setItem('initialUrl', location.state.initialUrl.pathname)
		}
	}
	const onGoogleLoginClick = () => {
		window.location.assign(data.GetGoogleAuthUrl);
	}



  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox>
            <MDBox mt={1} mb={1}>
				  <GoogleButton onClick={ onGoogleLoginClick } type={'dark'} style={{width: '100%'}}/>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
