import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import muiSettingsReducer from '../features/MuiSettings/muiSettingsSlice';
import userInfoReducer from '../features/userInfo/userInfoSlice';

export const store = configureStore({
	reducer: {
		counter: counterReducer,
		muiSettings: muiSettingsReducer,
		userInfo: userInfoReducer
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
	RootState,
	unknown,
	Action<string>>;
