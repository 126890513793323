import React, { JSXElementConstructor, Key, ReactElement, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { selectMuiState, setMiniSideNav, setOpenConfigurator } from "./features/MuiSettings/muiSettingsSlice";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./assets/theme";
import themeDark from "./assets/theme-dark";
import Sidenav from "./components/Common/Sidenav";
import { useBeforeunload } from 'react-beforeunload';


// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import routes from "./routes";
import Configurator from "./components/Common/Configurator";
import { GoogleCallbackHandler } from "./routes/auth/GoogleCallbackHandler";
import Login from "./routes/auth/login";
// import { selectUserInfoState } from "./features/userInfo/userInfoSlice";
import * as userInfoSlice from "./features/userInfo/userInfoSlice";
import { MeQuery, useGetUserRolesQuery, useMeQuery } from "./_generated/graphql";
import { AddFeedData } from "./routes/FeedData/addFeedData";


function App() {
	const onMeQueryComplete = (data: MeQuery) => {
		if (data.me && data.me.id) {
			const initialUrl = localStorage.getItem('initialUrl');
			if (initialUrl) {
				localStorage.removeItem('initialUrl');
				return <Navigate to={initialUrl} />;
			}
			dispatch(userInfoSlice.setInfo({
				picture: data.me.picture, name: data.me.name, id: data.me.id, email: data.me.email,
				role: { key: data.me.role.key, level: data.me.role.level }, loggedIn: true
			}));
		}
	}

	const { data: meData, error: meError, loading: meLoading, called: meCalled } = useMeQuery({ fetchPolicy: "network-only", });
	const { data: roleData, error: roleError, loading: roleLoading, called: roleCalled } = useGetUserRolesQuery({ fetchPolicy: "cache-and-network", });
	const userInfo = useAppSelector(userInfoSlice.selectUserInfoState);
	const muiSettings = useAppSelector(selectMuiState);
	const {
		miniSidenav,
		layout,
		openConfigurator,
		sidenavColor,
		transparentSidenav,
		whiteSidenav,
		darkMode,
	} = muiSettings;
	const { loggedIn, role } = userInfo;

	const dispatch = useAppDispatch();
	const location = useLocation();

	const [ onMouseEnter, setOnMouseEnter ] = useState(false);
	const { pathname } = useLocation();

	useBeforeunload(event => {
		localStorage.setItem('muiSettings', JSON.stringify(muiSettings));
	});

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			dispatch(setMiniSideNav(false));
			setOnMouseEnter(true);
		}
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			dispatch(setMiniSideNav(true));
			setOnMouseEnter(false);
		}
	};

	// Change the openConfigurator state
	const handleConfiguratorOpen = () => dispatch(setOpenConfigurator(!openConfigurator));
	const noNavBarPaths = ['/handlegooglecallback', '/login', '/feed'];

	const drawNavBar = noNavBarPaths.indexOf(pathname.toLowerCase()) === -1
		&& loggedIn
		&& layout === 'dashboard'

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [ pathname ]);

	const getRoutes = (allRoutes: any[]): any =>
		allRoutes.map(
			(route: {
				collapse: any;
				route: string;
				component: ReactElement<any, string | JSXElementConstructor<any>>;
				key: Key;
				roleLevel: number;
			}) => {
				if (route.roleLevel && route.roleLevel > role.level) {
					return null;
				}
				if (route.collapse) {
					return getRoutes(route.collapse);
				}

				if (route.route) {
					return <Route path={ route.route } element={ route.component } key={ route.key }/>;
				}

				return null;
			}
		);

	const configsButton = (
		<MDBox
			display="flex"
			justifyContent="center"
			alignItems="center"
			width="3.25rem"
			height="3.25rem"
			bgColor="white"
			shadow="sm"
			borderRadius="50%"
			position="fixed"
			right="2rem"
			bottom="2rem"
			zIndex={ 99 }
			color="dark"
			sx={ { cursor: "pointer" } }
			onClick={ handleConfiguratorOpen }
		>
			<Icon fontSize="small" color="inherit">
				settings
			</Icon>
		</MDBox>
	);

	if (meError) {
		return (
			<div>Error: <p>{meError.message}</p></div>
		)
	}
	if (roleError) {
		return (
			<div>Error: <p>{roleError.message}</p></div>
		)
	}

	if (meLoading || roleLoading) {
		return <div>Loading...</div>
	}

	if (!loggedIn && meData && meData.me) {
		dispatch(userInfoSlice.setInfo({
			picture: meData.me.picture, name: meData.me.name, id: meData.me.id, email: meData.me.email,
			role: { key: meData.me.role.key, level: meData.me.role.level }, loggedIn: true
		}));
		return <></>
	}


	return (
		!meData ? <div></div> :
			<ThemeProvider theme={ darkMode ? themeDark : theme }>
				<CssBaseline/>
				{ drawNavBar && (
					<>
						<Sidenav
							color={ sidenavColor }
							brand={ (transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite }
							brandName="2100 Artesia"
							routes={ routes }
							onMouseEnter={ handleOnMouseEnter }
							onMouseLeave={ handleOnMouseLeave }
						/>
						<Configurator/>
						{/*{ drawNavBar && configsButton }*/}
					</>
				) }
				{/*{layout === "vr" && <Configurator />}*/ }
				<Routes>
					{ loggedIn ?
						<>
							{ getRoutes(routes) }

							<Route path="/feed" element={ <AddFeedData /> }/>
							<Route path="*" element={ <Navigate to="/dashboard"/> }/>
						</>
						:
						<>
							<Route path="/login" element={ <Login/> }/>
							{/*<Route path="/dashboard" element={ <Dashboard/> }/>*/}
							<Route path="/handleGoogleCallback" element={ <GoogleCallbackHandler/> }/>
							<Route path="*" element={ <Navigate to={ "/login" } replace
																state={ { initialUrl: location } }/> }/>
						</>
					}
				</Routes>
			</ThemeProvider>
	);
}

export default App;
