import DashboardLayout from "../../Layouts/DashboardLayout";
import { Column } from "react-table";
import { FeedingDataTable } from "../FeedData/DataTable/FeedingDataTable";


export const Dashboard = () => {

	return (
		<DashboardLayout>
		</DashboardLayout>
	)
}


