import { ApolloClient, concat, from, fromPromise, HttpLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from 'graphql-ws';
import { getApiGqlUrl, getApiUrl, getWsUrl } from "./utils";

let authToken: string = '';
let isRefreshing = false;
let pendingRequests: (()=>void)[] = [];

const resolvePendingRequests = () => {
	pendingRequests.map(callback => callback());
	pendingRequests = [];
};

export const setAuthToken = (token: string) => {
	authToken = token;
}

export const getAuthToken = () => {
	return authToken;
}


const httpLink = new HttpLink({
	uri: getApiGqlUrl(),
	credentials: 'include',
	// headers: {
	// 	'x-forwarded-proto': 'https'
	// }
});

const wsLink = new GraphQLWsLink(createClient({
	url: getWsUrl(),
	connectionParams: {
		// authToken: user.authToken,
	},
}));

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink,
	httpLink,
);

export const setupSimpleApolloClient = () => {
	return new ApolloClient({
		cache: new InMemoryCache(),
		link: from([ errorLink, splitLink ])
	});
}

const errorLink = onError(
	({ graphQLErrors, networkError, operation, forward }) => {
		if (graphQLErrors) {
			for (let err of graphQLErrors) {
				if (err.extensions) {
					switch (err.extensions.code) {
						case 'UNAUTHENTICATED':
							// error code is set to UNAUTHENTICATED
							// when AuthenticationError thrown in resolver
							localStorage.setItem('initialUrl', window.location.pathname + window.location.search);
							window.location.href = '/login';
							break;

						// return forward$.flatMap(() => forward(operation));
					}
				} else {
					console.error(err);
				}
			}
		}
		if (networkError) {
			console.log(`[Network error]: ${networkError}`);
			// if you would also like to retry automatically on
			// network errors, we recommend that you use
			// apollo-link-retry
		}
	}
);



export const setupApolloClient = () => {
	// if (!userInfo) {
	const httpLink = new HttpLink({ uri: getApiGqlUrl() });
	const authLink = setContext((_, { headers }) => {
		return {
			headers: {
				...headers,
				authorization: getAuthToken(),
			}
		}
	});

	return new ApolloClient({
		cache: new InMemoryCache(),
		link: from([errorLink2, authLink, httpLink]),
	});
	// } else {
	// const wsClient = new SubscriptionClient(`ws://${API_HOST}/graphql`, {
	// 	// lazy: true,
	// 	connectionParams: async () => {
	// 	},
	// 	reconnect: true,
	// 	reconnectionAttempts: 5,
	// 	connectionCallback: (error: any, result: any) => {
	// 		// console.log(`Error: ${error}  RESULT: ${result}`);
	// 	}
	// });
	// wsClient.onError((err) => {
	// 	console.log(err);
	// })

	// const wsLink = new WebSocketLink(wsClient);
	//
	// const uploadLink = createUploadLink({uri: `http://${API_HOST}/graphql`});

	// const splitLink = split(
	// 	({query}) => {
	// 		// const definition = getMainDefinition(query);
	// 		const operationName = getOperationName(query);
	// 		return (!!operationName && httpOperations.includes(operationName));
	// 		// return (
	// 		// 	definition.kind === 'OperationDefinition' &&
	// 		// 	definition.operation === 'mutation' && definition.name?.value === 'saveFirmware'
	// 		// );
	// 	},
	// 	// TODO: Remove
	// 	(uploadLink as unknown) as ApolloLink,
	// 	wsLink,
	// );
	//
	// return new ApolloClient({
	// 	cache: new InMemoryCache(),
	// 	link: splitLink,
	// });
// }


}



const errorLink2 = onError(
	({ graphQLErrors, networkError, operation, forward }) => {
		if (graphQLErrors) {
			for (let err of graphQLErrors) {
				switch (err.extensions.code) {
					case 'UNAUTHENTICATED':
						// error code is set to UNAUTHENTICATED
						// when AuthenticationError thrown in resolver
						let forward$;

						if (!isRefreshing) {
							isRefreshing = true;
							forward$ = fromPromise(
								getNewToken()
									.then(({ accessToken }) => {
										// Store the new tokens for your auth link
										setAuthToken(accessToken);
										resolvePendingRequests();
										return accessToken;
									})
									.catch((error: any) => {
										pendingRequests = [];
										// Handle token refresh errors e.g clear stored tokens, redirect to login, ...
										return;
									})
									.finally(() => {
										isRefreshing = false;
									})
							).filter(value => Boolean(value));
						} else {
							// Will only emit once the Promise is resolved
							forward$ = fromPromise(
								new Promise(resolve => {
									pendingRequests.push(() => resolve(null));
								})
							);
						}

						return forward$.flatMap(() => forward(operation));
				}
			}
		}
		if (networkError) {
			console.log(`[Network error]: ${networkError}`);
			// if you would also like to retry automatically on
			// network errors, we recommend that you use
			// apollo-link-retry
		}
	}
);

const getNewToken = () => {
	return new Promise<{accessToken: string}>(async (resolve, reject) => {
		try {
			fetch(`${getApiUrl()}/auth/refresh`, {
				method: 'POST',
				credentials: 'include'
			})
				.then(data => data.json())
				.then(({access_token}) => {
					resolve(access_token);
			})
		} catch (e) {
			reject();
		}
	})

}

