import { LogoutMutation, MeQuery, useLogoutMutation } from "../../_generated/graphql";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";


export const Logout = (): any => {
	const onLogoutComplete = (data: LogoutMutation) => {
		window.location.href = '/';
	}


	const [ logoutMutation, { data } ] = useLogoutMutation({ onCompleted: onLogoutComplete });

	useEffect(() => {
		const logout = async () => {
			return await logoutMutation();
		}

		logout();

	}, [])

	if (data) {
		return <Navigate to={'/'}/>
	}
	// console.log(error);

	return null;
}
