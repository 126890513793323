import * as Yup from 'yup';

const baseSchema = Yup.object({
	ph: Yup.number().min(0).max(10).required('pH is required'),
	ec: Yup.number().min(0).max(20).required('EC is required'),
	ml: Yup.number().min(0).max(2000).required('ml is required'),
	roomId: Yup.string().not(['-1'], 'Please select room'),

})

export const vitalSchema = Yup.object({
	ppfd: Yup.number().min(0).max(2000).required('PPFD is required'),
	brix: Yup.number().min(0).max(30).required('PPFD is required'),
	roomId: Yup.string().not(['-1'], 'Please select room'),
	strainId: Yup.string().not(['-1'], 'Please select strain'),
	zone: Yup.number().not([-1], 'Please select zone'),
});

export const runoffSchema = baseSchema.concat(Yup.object({
	strainId: Yup.string().not(['-1'], 'Please select strain'),
	zone: Yup.number().not([-1], 'Please select zone'),
}));


export const feedSchema = baseSchema.concat(Yup.object({
	wateringNum: Yup.number().not([-1], 'Please select watering number'),
}));

export const initialValues = {
	ph: '',
	ec: '',
	ml: '',
	ppfd: '',
	brix: '',
	roomId: '-1',
	strainId: '-1',
	zone: -1,
	wateringNum: -1,
}


export const runoffInitialValues = {
	ph: '',
	ec: '',
	ml: '',
	roomId: '-1',
	strainId: '-1',
	zone: -1,
}



export const feedInitialValues = {
	ph: '',
	ec: '',
	ml: '',
	roomId: '-1',
	wateringNum: -1,
}



export const vitalInitialValues = {
	ppfd: 0,
	brix: 0,
	roomId: '-1',
	strainId: '-1',
	zone: -1,
}



