/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, ReactNode } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import { selectMuiState, setLayout } from "../../features/MuiSettings/muiSettingsSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import DashboardNavbar from "../../components/Common/Navbars/DashboardNavbar";
import Footer from "../../components/Common/Footer";

type DashboardLayoutProps = {
	hideNavbar?: boolean;
	hideFooter?: boolean;
	children?: ReactNode;
};


function DashboardLayout(props: DashboardLayoutProps): JSX.Element {
	const dispatch = useAppDispatch();
	const muiSettings = useAppSelector(selectMuiState);
  const { miniSidenav } = muiSettings;
  const { pathname } = useLocation();

  // useEffect(() => {
  //   dispatch(setLayout("dashboard"));
  // }, [pathname]);

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
		{ !props.hideNavbar && <DashboardNavbar/> }
      { props.children }
		{ !props.hideFooter && <Footer/> }
    </MDBox>
  );
}

export default DashboardLayout;
