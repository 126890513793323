import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { alpha, Icon } from "@mui/material";
import { light } from "@mui/material/styles/createPalette";
import MDTypography from "components/MDTypography";
import { selectMuiState } from "../../../features/MuiSettings/muiSettingsSlice";
import { useAppSelector } from "../../../app/hooks";
import { subDays } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useEffect, useState } from "react";
import MDDatePicker from "../../../components/MDDatePicker";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MDBox from "../../../components/MDBox";


export interface FeedingTableToolbarProps {
	title: string;
	numSelected: number;
	onDeleteClick: () => void;
	onDateApplyClick: (start: number, end: number) => void;
	dateRange: number[]
}


export const FeedingTableToolbar = (props: FeedingTableToolbarProps) => {
	const muiSettings = useAppSelector(selectMuiState);
	const { darkMode } = muiSettings;

	const [ selectedDateRange, setSelectedDateRange ] = useState([ props.dateRange[0], props.dateRange[1] ]);
	const [ oldDateRangeValue, setOldDateRangeValue ] = useState([selectedDateRange[0], selectedDateRange[1]]);


	useEffect(() => {
	}, [])

	const iconsStyle = ({
							palette: { dark, white, text },
							functions: { rgba },
						}: {
		palette: any;
		functions: any;
	}) => ({
		color: () => {
			let colorValue = light || darkMode ? white.main : dark.main;

			// if (!light) {
			// 	colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
			// }

			return colorValue;
		},
	});

	const handleDeleteClick = () => {
		props.onDeleteClick();
	}

	const handleApplyBtnClick = () => {
		props.onDateApplyClick(selectedDateRange[0], selectedDateRange[1]);
		setOldDateRangeValue(selectedDateRange);
	}

	const handleCancelBtnClick = () => {
		setSelectedDateRange(oldDateRangeValue);
	}

	const handleDateRangeChange = (selectedDates: Date[], dateStr: string, instance: any) => {
		if (selectedDates.length === 1) return;
		setSelectedDateRange([selectedDates[0].getTime(), selectedDates[1].getTime()]);
	}

	const renderToolbarLeft = () => {
		return (
			<MDTypography
				sx={ { flex: '1 1 100%' } }
				variant="h6"
				id="tableTitle"
				component="div"
			>
				{ props.title }
			</MDTypography>
		)
	}


	const renderToolbarRight = () => {
		return (
			<>
				<MDTypography variant="h6" mr={1} component="div">Range: </MDTypography>
				<MDDatePicker input={{ style: { minWidth: '190px' } }}
							  options={{mode: 'range', showMonths: 2, onChange: handleDateRangeChange }}
							  value={selectedDateRange}
				/>
				{ oldDateRangeValue[0] !== selectedDateRange[0] || oldDateRangeValue[1] !== selectedDateRange[1] ? (
				<MDBox style={{ display: 'flex', alignItems: 'center' }}>
					<Tooltip title={ 'Apply' }>
						<IconButton onClick={ handleApplyBtnClick } sx={{ color: 'green' }}>
							<CheckCircleIcon/>
						</IconButton>
					</Tooltip>
					<Tooltip title={ 'Cancel' }>
						<IconButton onClick={ handleCancelBtnClick } sx={{ color: 'red' }}>
							<Icon>cancel</Icon>
						</IconButton>
					</Tooltip>
				</MDBox>
					)
					: '' }
			</>
		)
	}


	const renderSelectedLeft = () => {
		return (
			<MDTypography
				sx={ { flex: '1 1 100%' } }
				// color="inherit"
				variant="subtitle1"
				component="div"
			>
				{ props.numSelected } selected
			</MDTypography>
		)
	}

	const renderSelectedRight = () => {
		return (
			<Tooltip title="Delete">
				<IconButton onClick={ handleDeleteClick }>
					<Icon color={ 'secondary' }>delete</Icon>
				</IconButton>
			</Tooltip>
		)
	}


	return (
		<Toolbar
			sx={ {
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(props.numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(theme.palette.primary.contrastText, theme.palette.action.activatedOpacity),
					// theme.palette.badgeColors,
				}),
			} }
		>
			{ props.numSelected > 0 ? (
				renderSelectedLeft()
			) : (
				renderToolbarLeft()
			) }
			{ props.numSelected > 0 ? (
				renderSelectedRight()
			) : (
				renderToolbarRight()
			) }
		</Toolbar>
	)
}



