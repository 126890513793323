import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";



interface UserInfoState {
	loggedIn: boolean;
	id: string;
	name: string;
	email: string;
	picture: string;
	role?: { key: string, level: number };
}

const initialState: UserInfoState = {
	loggedIn: false,
	id: '',
	name: '',
	email: '',
	picture: '',
	role: null,
}

export const userInfoSlice = createSlice({
	name: 'muiSettings',
	initialState,
	reducers: {
		setInfo: (state, action: PayloadAction<UserInfoState>) => {
			state.loggedIn = action.payload.loggedIn;
			state.id = action.payload.id;
			state.email = action.payload.email;
			state.name = action.payload.name;
			state.picture = action.payload.picture;
			state.role = action.payload.role;
		},
		setLoggedIn: (state, action: PayloadAction<boolean>) => {
			state.loggedIn = action.payload;
		},
		setName: (state, action: PayloadAction<string>) => {
			state.name = action.payload;
		},
		setEmail: (state, action: PayloadAction<string>) => {
			state.email = action.payload;
		},
		setPicture: (state, action: PayloadAction<string>) => {
			state.picture = action.payload;
		},
		setRole: (state, action: PayloadAction<{ key: string, level: number }>) => {
			state.role = action.payload;
		},
	}
});


export const {
	setLoggedIn, setName, setEmail, setPicture, setInfo
} = userInfoSlice.actions;

export const selectUserInfoState = (state: RootState) => state.userInfo;

export default userInfoSlice.reducer;

