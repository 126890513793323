import { useGoogleCallbackMutation } from "../../_generated/graphql";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import * as userInfo from "../../features/userInfo/userInfoSlice";


export const GoogleCallbackHandler = (): any => {
	const dispatch = useAppDispatch();
	const [ googleCallback, { data, error, loading } ] = useGoogleCallbackMutation({
		variables: { queryString: window.location.search
		}});

	useEffect(() => {
		const login = async () => {
			return await googleCallback();
		}

		login();

	}, [])

	if (data) {
		if (data.GoogleCallback.result.ok) {
			const initialUrl = localStorage.getItem('initialUrl');
			localStorage.removeItem('initialUrl');
			const newUrl = `${window.location.protocol}//${window.location.host}${initialUrl ?? ''}`;
			window.location.replace(newUrl);
			return null;
		} else {
			if (data.GoogleCallback.result.errorMsg) {
				alert(data.GoogleCallback.result.errorMsg);
			}
			return <Navigate to={'/login'} />
		}
	}
	// console.log(error);

	return null;
}
