import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import BasicLayout from "../auth/login/BasicLayout/layout";
import Icon from "@mui/material/Icon";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import {
	Backdrop,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Tabs,
	useMediaQuery
} from "@mui/material";
import React, { useState } from "react";
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import Grid from "@mui/material/Grid";
import FormField from "../../components/FormField";
import {
	useGetPlantingRoomsQuery,
	useGetStrainsQuery,
	useSaveFeedingDataMutation,
	useSaveRunoffDataMutation,
	useSaveVitalDataMutation
} from "../../_generated/graphql";
import MenuItem from "@mui/material/MenuItem";
import MDButton from "../../components/MDButton";
import { Form, Formik } from "formik";
import { feedSchema, initialValues, runoffSchema, vitalSchema } from "./form";
import theme from "../../assets/theme";
import MDDatePicker from "../../components/MDDatePicker";
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';


type DataSwitchType = 'Runoff' | 'Feeding' | 'Vital';

export const AddFeedData = () => {
	const [ amPmTabValue, setAmPmTabValue ] = useState('am');
	const [ tabValue, setTabValue ] = useState<DataSwitchType>('Runoff');
	const [ title, setTitle ] = useState('Runoff');
	const { data: rooms, error: roomsError, loading: roomsLoading }
		= useGetPlantingRoomsQuery({ fetchPolicy: "network-only" });
	const { data: strains, error: strainsError, loading: strainsLoading }
		= useGetStrainsQuery({ fetchPolicy: "network-only" });
	const formikRef = React.useRef(null);
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [ saveDialogContent, setSaveDialogContent ] = useState('');
	const [ saveDialogOpen, setSaveDialogOpen ] = useState(false);
	const [ saveDialogColor, setSaveDialogColor ] = useState('info');
	const [ backdropOpen, setBackdropOpen ] = useState(false);
	const [ selectedDate, setSelectedDate ] = useState(new Date().getTime());


	const [ saveFeedingData, { data: feedingData, error: feedingError, loading: feedingLoading } ] = useSaveFeedingDataMutation();
	const [ saveRunoffData, { data: runoffData, error: runoffError, loading: runoffLoading } ] = useSaveRunoffDataMutation();
	const [ saveVitalData, { data: vitalData, error: vitalError, loading: vitalLoading } ] = useSaveVitalDataMutation();

	const handleSetTabValue = (event: any, newValue: any) => {
		setTabValue(newValue);
		setTitle(newValue);
		formikRef.current && formikRef.current.resetForm();
		setSelectedDate(new Date().getTime());
	}

	const handleDateRangeChange = (selectedDates: Date[], dateStr: string, instance: any) => {
		setSelectedDate(selectedDates[0].getTime());
	}

	const handleAmPmTabValue = (event: any, newValue: any) => {
		setAmPmTabValue(newValue);
	}

	const handleDialogOk = () => {
		setSaveDialogOpen(false);
		setSaveDialogContent('');
	}

	const handleSubmit = async (values: any, actions: any) => {
		let data: any = { roomId: values.roomId };
		let fn;
		switch (tabValue) {
			case "Feeding":
				data.ec = values.ec;
				data.ph = values.ph;
				data.ml = values.ml;
				data.wateringNum = parseInt(values.wateringNum);
				fn = saveFeedingData.bind(this, {variables: { data, timestamp: selectedDate }});
				break;
			case "Runoff":
				data.ec = values.ec;
				data.ph = values.ph;
				data.ml = values.ml;
				data.zone = parseInt(values.zone);
				data.isAm = amPmTabValue === 'am';
				data.strainId = values.strainId;
				fn = saveRunoffData.bind(this, {variables: { data, timestamp: selectedDate }});
				break;
			case "Vital":
				data.ppfd = values.ppfd;
				data.brix = values.brix;
				data.zone = parseInt(values.zone);
				data.strainId = values.strainId;
				fn = saveVitalData.bind(this, {variables: { data, timestamp: selectedDate }});
				break;
		}

		fn()
			.then((value: any) => {
				setBackdropOpen(false);
				setSaveDialogContent('Data successfully saved!');
				setSaveDialogColor('info');
				setSaveDialogOpen(true);
			})
			.catch(reason => {
				setBackdropOpen(false);
				setSaveDialogColor('error')
				setSaveDialogContent(`Error saving data:\n${ reason.message ?? reason }`);
				setSaveDialogOpen(true);
			})
			.finally(() => {
				actions.setSubmitting(false);
				// actions.resetForm();
			});
	};


	const getDatePicker = () => {
		return (
			<MDDatePicker input={ { style: { minWidth: '190px' } } }
						  label={ "Date" }
						  variant={ "standard" }
						  options={ { mode: 'single', showMonths: 1, onChange: handleDateRangeChange } }
						  value={ selectedDate }
			/>
		)
	}

	if (!rooms || !strains) {
		return <div>Loading...</div>
	}

	return (
		<BasicLayout>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={ backdropOpen }
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Dialog fullScreen={fullScreen} open={ saveDialogOpen } fullWidth={ true }>
				<DialogContent>
					<DialogContentText>
						<MDTypography variant={ 'body1' } style={{whiteSpace: 'pre-wrap'}} color={ saveDialogColor } component={'text'} my={ '8px' }>
						{ saveDialogContent }
						</MDTypography>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<MDButton autoFocus onClick={ handleDialogOk }>
						OK
					</MDButton>
				</DialogActions>
			</Dialog>
			<Card>
				<MDBox
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"
					mx={ 2 }
					mt={ -3 }
					p={ 2 }
					mb={ 1 }
					textAlign="center"
				>
					<MDTypography variant="h4" fontWeight="medium" color="white" mt={ 1 }>
						{ title } data
					</MDTypography>
				</MDBox>
				<MDBox pt={ 4 } pb={ 3 } px={ 3 }>
					<MDBox>
						<MDBox mt={ 1 } mb={ 1 }>
							<AppBar position="static">
								<Tabs orientation={ 'horizontal' } value={ tabValue } onChange={ handleSetTabValue }>
									<Tab
										data-name={ 'Runoff' }
										label="Runoff"
										icon={
											<InvertColorsIcon/>
										}
										value={ 'Runoff' }
									/>
									<Tab
										data-name={ 'Feeding' }
										label="Feeding"
										icon={
											<Icon fontSize="small" sx={ { mt: -0.25 } }>
												shower
											</Icon>
										}
										value={ 'Feeding' }
									/>
									<Tab
										data-name={ 'Vital' }
										label="Vital"
										icon={
											<MonitorHeartIcon fontSize="small" sx={ { mt: -0.25 } } />
										}
										value={ 'Vital' }
									/>
								</Tabs>
							</AppBar>

						</MDBox>
					</MDBox>

					<Formik
						initialValues={ initialValues }
						validationSchema={ tabValue === 'Feeding' ? feedSchema : tabValue === 'Runoff' ? runoffSchema : vitalSchema }
						onSubmit={ handleSubmit }
						innerRef={ formikRef }
					>
						{({ values, errors, touched, isSubmitting,
							  handleChange, handleBlur, dirty }) => (

						<Form>

							<MDBox pb={ 3 } px={ 3 }>
								<Grid container spacing={ 3 }>
									{ tabValue === 'Feeding' || tabValue === 'Runoff' ?
										<Grid item xs={ 12 }>
											<Grid container spacing={ 3 }>
												<Grid item xs={ 12 } sm={ 4 }>
													<FormField label="EC" InputLabelProps={ { shrink: true } }
															   value={ values.ec }
															   name={ 'ec' }
															   type={ 'number' }
															   onChange={ handleChange }
															   error={ errors.ec && touched.ec }
															   onBlur={ handleBlur }
															   success={ values.ec.length > 0 && !errors.ec }
															   inputProps={ {
																   min: '0',
																   max: '20',
																   step: '0.1'
															   } }

													/>
												</Grid>
												<Grid item xs={ 12 } sm={ 4 }>
													<FormField label="pH" InputLabelProps={ { shrink: true } }
															   value={ values.ph }
															   name={ 'ph' }
															   onChange={ handleChange }
															   error={ errors.ph && touched.ph }
															   onBlur={ handleBlur }
															   success={ values.ph.length > 0 && !errors.ph }
															   type={ 'number' }
															   inputProps={ {
																   min: '0',
																   max: '10',
																   step: '0.1'
															   } }
													/>
												</Grid>
												<Grid item xs={ 12 } sm={ 4 }>
													<FormField label="ml" InputLabelProps={ { shrink: true } }
															   value={ values.ml }
															   name={ 'ml' }
															   onChange={ handleChange }
															   error={ errors.ml && touched.ml }
															   onBlur={ handleBlur }
															   success={ values.ml.length > 0 && !errors.ml }
															   type={ 'number' }
															   inputProps={ {
																   min: '0',
																   max: '2000',
																   step: '5'
															   } }
													/>
												</Grid>
											</Grid>
										</Grid>
										:
										<Grid item xs={ 12 }>
											<Grid container spacing={ 3 }>
												<Grid item xs={ 12 } sm={ 6 }>
													<FormField label="PPFD" InputLabelProps={ { shrink: true } }
															   value={ values.ppfd }
															   name={ 'ppfd' }
															   onChange={ handleChange }
															   error={ errors.ppfd && touched.ppfd }
															   onBlur={ handleBlur }
															   success={ values.ppfd.length > 0 && !errors.ppfd }
															   type={ 'number' }
															   inputProps={ {
																   min: '0',
																   max: '2000',
																   step: '1'
															   } }
													/>
												</Grid>
												<Grid item xs={ 12 } sm={ 6 }>
													<FormField label="Brix" InputLabelProps={ { shrink: true } }
															   value={ values.brix }
															   name={ 'brix' }
															   onChange={ handleChange }
															   error={ errors.brix && touched.brix }
															   onBlur={ handleBlur }
															   success={ values.brix.length > 0 && !errors.brix }
															   type={ 'number' }
															   inputProps={ {
																   min: '0',
																   max: '30',
																   step: '0.1'
															   } }
													/>
												</Grid>
											</Grid>
										</Grid>
									}
									<Grid item xs={ 12 } sm={ 4 }>
										<FormField
											label="Room"
											select
											InputLabelProps={ { shrink: true } }
											// value={ selectedRoom }
											name={ 'roomId' }
											value={ values.roomId }
											error={ errors.roomId && touched.roomId }
											success={ values.roomId !== '-1' && !errors.roomId }
											onChange={ handleChange }
											onBlur={ handleBlur }
											// onChange={ handleRoomSelectChange }
										>
											<MenuItem key={ 'room-select-none' } value={ '-1' } selected={ true }>
												{ '---Select Room---' }
											</MenuItem>
											{ rooms.GetPlantingRooms
												.sort((a, b) => a.name > b.name ? 1 : -1)
												.map((r) => {
													return <MenuItem key={ r.id } value={ r.id }>{ r.name }</MenuItem>
												}) }
										</FormField>
									</Grid>
									{ tabValue === 'Feeding' ? '' :
										<>
											<Grid item xs={ 12 } sm={ 4 }>
												<FormField
													label="Strain"
													select
													name={ 'strainId' }
													onChange={ handleChange }
													onBlur={ handleBlur }
													InputLabelProps={ { shrink: true } }
													// value={ selectedStrain }
													value={ values.strainId }
													error={ errors.strainId && touched.strainId }
													success={ values.strainId !== '-1' && !errors.strainId }
													// onChange={ handleStrainSelectChange }
												>
													<MenuItem key={ 'strain-select-none' } value={ '-1' }
															  selected={ true }>
														{ '---Select Strain---' }
													</MenuItem>
													{ strains.GetStrains
														.sort((a, b) => a.name > b.name ? 1 : -1)
														.map(r => {
															return <MenuItem key={ r.id }
																			 value={ r.id }>{ r.name }</MenuItem>
														}) }
												</FormField>
											</Grid>
											<Grid item xs={ 12 } sm={ 4 }>
												<FormField
													label="Zone"
													select
													name={ 'zone' }
													InputLabelProps={ { shrink: true } }
													// value={ selectedZone }
													value={ values.zone }
													error={ errors.zone && touched.zone }
													success={ values.zone !== -1 && !errors.zone }
													onChange={ handleChange }
													onBlur={ handleBlur }
													// onChange={ handleZoneSelectChange }
												>
													<MenuItem key={ 'zone-select-none' } value={ '-1' }
															  selected={ true }>
														{ '---Select Zone---' }
													</MenuItem>
													{ [ 1, 2, 3, 4 ]
														.map(r => {
															return <MenuItem key={ `zone-${ r }` }
																			 value={ r + '' }>{ `Zone ${ r }` }</MenuItem>
														}) }
												</FormField>
											</Grid>
										</>
									}
									{ tabValue === 'Feeding' ?
										<>
										<Grid item xs={ 12 } sm={ 4 }>
											<FormField
												label="Watering Number"
												select
												name={ 'wateringNum' }
												InputLabelProps={ { shrink: true } }
												// value={ selectedWateringNum }
												value={ values.wateringNum }
												error={ errors.wateringNum && touched.wateringNum }
												success={ values.wateringNum !== -1 && !errors.wateringNum }
												onChange={ handleChange }
												onBlur={ handleBlur }
												// onChange={ handleWateringNumSelectChange }
											>
												<MenuItem key={ 'watering-num-select-none' } value={ '-1' }
														  selected={ true }>
													{ '---Select Watering Number---' }
												</MenuItem>
												{ [ ...Array(14).keys() ]
													.map(r => {
														return <MenuItem key={ `zone-${ r + 1 }` }
																		 value={ `${ r + 1 }` }>{ r + 1 }</MenuItem>
													}) }
											</FormField>
										</Grid>
										<Grid item xs={ 12 } sm={ 4 }>
											{ getDatePicker() }
										</Grid>
										</>
									: '' }
									{ tabValue === 'Vital' ?
										<Grid item xs={ 12 } sm={ 4 }>
											<Grid item xs={ 12 } sm={ 4 }>
												{ getDatePicker() }
											</Grid>
										</Grid>
										: '' }
									{ tabValue === 'Runoff' ?
										<Grid container justifyContent={ 'space-around' }
											  px={3}
											  my={2}
											  alignItems={ 'center' }>
											<Grid  item xs={ 12 } sm={ 6 }>
												{ getDatePicker() }
											</Grid>
											<Grid item xs={ 12 } sm={ 6 }>
												<MDBox mt={ 2 } mb={ 2 }>
													<AppBar position="static">
														<Tabs orientation={ 'horizontal' } value={ amPmTabValue }
															  onChange={ handleAmPmTabValue }>
															<Tab
																data-name={ 'Runoff' }
																label="AM"
																value={ 'am' }
															/>
															<Tab
																data-name={ 'Feeding' }
																label="PM"
																value={ 'pm' }
															/>
														</Tabs>
													</AppBar>
												</MDBox>
											</Grid>
										</Grid>
									: '' }
								</Grid>
							</MDBox>
							<Grid item xs={ 12 } sm={ 6 }>
								<MDBox ml="auto">
									<MDButton variant="gradient" color="dark" size="small"
											  // disabled={ isSubmitting || Object.keys(errors).length > 0 || !dirty }
											  type={ 'submit' }>
										save
									</MDButton>
								</MDBox>
							</Grid>

						</Form>
							)}
					</Formik>

				</MDBox>
			</Card>

		</BasicLayout>
	)
}

