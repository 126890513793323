/**
=========================================================
 * Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
 */

// Material Dashboard 2 PRO React layouts
// import Analytics from "layouts/dashboards/analytics";
// import Sales from "layouts/dashboards/sales";
// import ProfileOverview from "layouts/pages/profile/profile-overview";
// import Settings from "layouts/pages/account/settings";
// import Wizard from "layouts/applications/wizard";
// import DataTables from "layouts/applications/data-tables";
// import Calendar from "layouts/applications/calendar";
// import SignInBasic from "layouts/authentication/sign-in/basic";
// import SignInCover from "layouts/authentication/sign-in/cover";
// import SignInIllustration from "layouts/authentication/sign-in/illustration";
// import SignUpCover from "layouts/authentication/sign-up/cover";
// import ResetCover from "layouts/authentication/reset-password/cover";

// Material Dashboard 2 PRO React TS components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import { Dashboard } from "./routes/Dashboard";
import { FeedingData } from "./routes/FeedData/feeding-data";
import { Logout } from "./routes/auth/Logout";

const routes = [
	{
		type: "collapse",
		name: "Brooklyn Alice",
		key: "user-item",
		icon: <MDAvatar src={ profilePicture } alt="Brooklyn Alice" size="sm"/>,
		collapse: [
			{
				name: "My Profile",
				key: "my-profile",
				route: "#",
				// component: <ProfileOverview />,
				component: <></>,
			},
			{
				name: "Settings",
				key: "profile-settings",
				route: "#",
				// component: <Settings />,
				component: <></>,
			},
			{
				name: "Logout",
				key: "logout",
				route: "/logout",
				component: <Logout />,
			},
		],
	},
	{ type: "divider", key: "divider-0" },
	{
		type: 'collapse',
		name: 'Dashboard',
		key: 'dashboard',
		icon: <Icon fontSize="medium">dashboard</Icon>,
		route: '/dashboard',
		component: <Dashboard />,
		noCollapse: true,
		roleLevel: 1,
	},
	{
		type: "collapse",
		name: "Feeding data",
		key: "feeding-data",
		route: '/feeding-data',
		icon: <Icon fontSize="medium">receipt_long</Icon>,
		noCollapse: true,
		component: <FeedingData />,
		roleLevel: 50,
	},
	{
		type: "collapse",
		name: "Settings",
		key: "settings",
		icon: <Icon fontSize="medium">settings</Icon>,
		roleLevel: 100,
		collapse: [
			{
				name: "Users",
				key: "settings-users",
				route: "#",
				// component: <ProfileOverview />,
				component: <></>,
			},
			{
				name: "Strains",
				key: "settings-strains",
				route: "#",
				// component: <ProfileOverview />,
				component: <></>,
			},
		]


	}

];

export default routes;
