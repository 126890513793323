import DashboardLayout from "../../Layouts/DashboardLayout";
import {
	AppBar,
	Backdrop,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	TableContainer,
	Tabs,
	useMediaQuery,
	useTheme
} from "@mui/material";
import MDBox from "../../components/MDBox";
import { DataTable } from "../../components/DataTable/DataTable";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import React, { useEffect, useRef, useState } from "react";
import { FeedingTableToolbar } from "./DataTable/FeedingTableToolbar";
import { addDays, subDays } from "date-fns";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import { AddEditData, TabValueType } from "./addEditData";
import theme from "../../assets/theme";
import {
	useDeleteFeedingDataMutation,
	useDeleteRunoffDataMutation, useDeleteVitalDataMutation,
	useGetFeedingDataLazyQuery,
	useGetPlantingRoomsQuery, useGetRunoffDataLazyQuery,
	useGetStrainsQuery, useGetVitalDataLazyQuery, useSaveFeedingDataMutation,
	useSaveRunoffDataMutation, useSaveVitalDataMutation
} from "../../_generated/graphql";


const feedingColumns = [
	{ accessor: "id", width: "5%" },
	{ Header: 'Date', accessor: "dateStr" },
	{ Header: 'Room', accessor: "room.name" },
	{ Header: 'EC', accessor: "ec" },
	{ Header: 'pH', accessor: "ph" },
	{ Header: 'ml', accessor: "ml" },
	{ Header: 'Watering №' , accessor: "wateringNum" },
	{ Header: 'User' , accessor: "user" },
	{ Header: 'Action' , accessor: "actions", width: '7%' },
];

const runoffColumns = [
	{ accessor: "id", width: "5%" },
	{ Header: 'Date', accessor: "dateStr" },
	{ Header: 'AM/PM', accessor: "ampm" },
	{ Header: 'Room', accessor: "room.name" },
	{ Header: 'EC', accessor: "ec" },
	{ Header: 'pH', accessor: "ph" },
	{ Header: 'ml', accessor: "ml" },
	{ Header: 'Strain' , accessor: "strain.name" },
	{ Header: 'Zone' , accessor: "zoneName" },
	{ Header: 'User' , accessor: "user" },
	{ Header: 'Action' , accessor: "actions", width: '7%' },
];


const vitalColumns = [
	{ accessor: "id", width: "5%" },
	{ Header: 'Date', accessor: "dateStr" },
	{ Header: 'Room', accessor: "room.name" },
	{ Header: 'PPFD', accessor: "ppfd" },
	{ Header: 'Brix', accessor: "brix" },
	{ Header: 'Strain' , accessor: "strain.name" },
	{ Header: 'Zone' , accessor: "zoneName" },
	{ Header: 'User' , accessor: "user" },
	{ Header: 'Action' , accessor: "actions", width: '7%' },
];

export const FeedingData = () => {
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [ dialogContent, setDialogContent ] = useState('');
	const [ dialogShowOk, setDialogShowOk ] = useState(true);
	const [ dialogOpen, setDialogOpen ] = useState(false);
	const [ dialogColor, setDialogColor ] = useState('info');
	const [tabValue, setTabValue] = useState<TabValueType>('feed');
	const { data: rooms, loading: roomsLoading, error: roomsError } = useGetPlantingRoomsQuery();
	const { data: strains, loading: strainsLoading, error: strainsError } = useGetStrainsQuery();
	const [ selectedRows, setSelectedRows ] = useState([]);
	const [ currentPage, setCurrentPage ] = useState(0);
	const [ rowsPerPage, setRowsPerPage ] = useState(10);
	const [ selectedDateRange, setSelectedDateRange ] = useState<Array<number>>([
		subDays(new Date().setHours(0, 0, 0, 0), 10).getTime(),
		addDays(new Date().setHours(0, 0, 0, 0), 1).getTime()
	]);
	let idsToDelete = useRef([]);
	const [ editData, setEditData ] = useState({});
	const [ editDialogOpen, setEditDialogOpen ] = useState(false);

	const currentTheme = useTheme();


	const [ fetchRunoffData, { data: runoffData, loading: runoffDataLoading, error: runoffDataError,
		called: runoffDataCalled, refetch: runoffDataRefetch }]
		= useGetRunoffDataLazyQuery({ variables:
			{ page: currentPage, rows: rowsPerPage, fromDate: selectedDateRange[0]!, toDate: selectedDateRange[1]! }
		});
	const [ deleteRunoffData, { loading: deleteRunoffLoading, error: deleteRunoffError }] = useDeleteRunoffDataMutation();
	const [ saveRunoffData, { loading: saveRunoffLoading, error: saveRunoffError }] = useSaveRunoffDataMutation();

	const [ fetchFeedingData, { data: feedingData, loading: feedingDataLoading, error: feedingDataError,
		called: feedingDataCalled, refetch: feedingDataRefetch }]
		= useGetFeedingDataLazyQuery({ variables:
			{ page: currentPage, rows: rowsPerPage, fromDate: selectedDateRange[0]!, toDate: selectedDateRange[1]! }
		});
	const [ deleteFeedingData, { loading: deleteFeedingLoading, error: deleteFeedingError }] = useDeleteFeedingDataMutation();
	const [ saveFeedingData, { loading: saveFeedingLoading, error: saveFeedingError }] = useSaveFeedingDataMutation();

	const [ fetchVitalData, { data: vitalData, loading: vitalDataLoading, error: vitalDataError,
		called: vitalDataCalled, refetch: vitalDataRefetch }]
		= useGetVitalDataLazyQuery({ variables:
			{ page: currentPage, rows: rowsPerPage, fromDate: selectedDateRange[0]!, toDate: selectedDateRange[1]! }
		});
	const [ deleteVitalData, { loading: deleteVitalLoading, error: deleteVitalError }] = useDeleteVitalDataMutation();
	const [ saveVitalData, { loading: saveVitalLoading, error: saveVitalError }] = useSaveVitalDataMutation();


	// const { data: sydSbpData, loading: sydSbpLoading } = useSystemEventsSubscription()
	//
	// !sydSbpLoading && sydSbpData && console.log(sydSbpData.systemEvent);

	useEffect(() => {
		switch (tabValue) {
			case "feed":
				fetchFeedingData();
				break;
			case "runoff":
				fetchRunoffData();
				break;
			case "vital":
				fetchVitalData();
				break;
		}
	}, [ ]);

	const [ backdropOpen, setBackdropOpen ] = useState(false);

	const handleTabChange = (event: any, newValue: TabValueType) => {
		setBackdropOpen(true);
		let fn;
		switch (newValue) {
			case "feed":
				fn = fetchFeedingData;
				break;
			case "runoff":
				fn = fetchRunoffData;
				break;
			case "vital":
				fn = fetchVitalData;
				break;
		}
		fn({ fetchPolicy: "no-cache" })
			.then(() => {
				setTabValue(newValue);
			})
			.catch(reason => {
				showErrorDialog(`Error fetching data:\n${reason.message ?? reason}`);
			})
			.finally(() => {
				setBackdropOpen(false);
			});
	}


	const handleToolbarDeleteClick = () => {
		idsToDelete.current = selectedRows;
		let content = `Delete record${ selectedRows.length > 1 ? 's' : ''}?`;
		const data: any = tabValue === 'feed' ? feedingData.GetFeedingData.data : tabValue === 'runoff' ? runoffData.GetRunoffData.data : vitalData.GetVitalData.data;
		const rows = data.filter((d: any) => selectedRows.indexOf(d.id) > -1);
		tabValue === 'vital'
			? rows.forEach((r: any) => content += `\n${r.dateStr}\tPPFD: ${r.ppfd} \tBRIX: ${r.brix}`)
			: rows.forEach((r: any) => content += `\n${r.dateStr}\tEC: ${r.ec} \tPH: ${r.ph}\tML: ${r.ml}`);

		showInfoDialog(content, false);
	}

	const handleDateApplyClick = (start: number, end: number) => {
		setSelectedDateRange([ start, end ]);
	}

	const handleCellEditClick = (id: string) => {
		let data;
		switch (tabValue) {
			case "runoff":
				data = runoffData.GetRunoffData.data.find(d => d.id === id);
				data = { strainId: data.strain.id, roomId: data.room.id, ...data };
				break;
			case "feed":
				data = feedingData.GetFeedingData.data.find(d => d.id === id);
				data = { roomId: data.room.id, ...data }
				break;
			case "vital":
				data = vitalData.GetVitalData.data.find(d => d.id === id);
				data = { strainId: data.strain.id, roomId: data.room.id, ...data };
				break;
		}
		setEditData(data);
		// setEditData(tabValue === 'feed' ? { roomId: data.room.id, ...data }
		// 	: tabValue === 'runoff' ? { strainId: data.strain.id, roomId: data.room.id, ...data }
		// 	: { ppfd: data.ppfd, brix: data.brix, strainId: data.strain.id, roomId: data.room.id, ...data });
		setEditDialogOpen(true);
	}
	const handleEditDialogCancel = () => {
		setEditDialogOpen(false);
	}
	const handleEditDialogSave = (values: any, actions: any) => {
		let vars, editMutation, refetchFn: any;
		switch (tabValue) {
			case "runoff":
				editMutation = saveRunoffData.bind(this, { variables: { data: {
							id: values.id, ec: parseFloat(values.ec), ph: parseFloat(values.ph), ml: parseFloat(values.ml), roomId: values.roomId,
							strainId: values.strainId, isAm: values.isAm, zone: parseInt(values.zone)
						}, timestamp: parseFloat(values.timestamp) } });
				refetchFn = runoffDataRefetch;
				break;
			case "feed":
				editMutation = saveFeedingData.bind(this, { variables: { data: {
							id: values.id, ec: parseFloat(values.ec), ph: parseFloat(values.ph), ml: parseFloat(values.ml), roomId: values.roomId,
							wateringNum: parseInt(values.wateringNum)
						}, timestamp: parseFloat(values.timestamp) } });
				refetchFn = feedingDataRefetch;
				break;
			case "vital":
				vars = { data: { id: values.id, ppfd: parseFloat(values.ppfd), brix: parseFloat(values.brix), roomId: values.roomId,
						strainId: values.strainId, zone: parseInt(values.zone)},
					timestamp: parseFloat(values.timestamp) };
				editMutation = saveVitalData.bind(this, { variables: { data: {
					ppfd: parseFloat(values.ppfd), brix: parseFloat(values.brix), roomId: values.roomId,
							strainId: values.strainId, zone: parseInt(values.zone)
						}, timestamp: parseFloat(values.timestamp) } });
				refetchFn = vitalDataRefetch;
				break;
		}

		// const editMutation1 = tabValue === 'feed'
		// 	? saveFeedingData.bind(this, { variables: vars })
		// 	: saveRunoffData.bind(this, { variables: vars });
		editMutation()
			.then(() => {
				showInfoDialog('Data successfully saved!', true);
				refetchFn();
			})
			.catch(reason => {
				showErrorDialog(`Error saving data:\n${ reason.message ?? reason }`);
			})
			.finally(() => {
				setDialogShowOk(false);
				setEditDialogOpen(false);
			})
	}

	const handleCellDeleteClick = (id: string) => {
		idsToDelete.current = [id];
		let content;
		let row;
		switch (tabValue) {
			case "vital":
				row = vitalData.GetVitalData.data.find(d => d.id === id);
				content = `Delete record\n${row.dateStr}\tPPFD: ${row.ppfd} \tBRIX: ${row.brix} ?`;
				break;
			case "runoff":
				row = runoffData.GetRunoffData.data.find(d => d.id === id);
				content = `Delete record\n${row.dateStr}\tEC: ${row.ec} \tPH: ${row.ph}\tML: ${row.ml} ?`;
				break;
			case "feed":
				row = feedingData.GetFeedingData.data.find(d => d.id === id);
				content = `Delete record\n${row.dateStr}\tEC: ${row.ec} \tPH: ${row.ph}\tML: ${row.ml} ?`;
				break;
		}
		showInfoDialog(content, false);
	}

	const handlePageChange = (pageNum: number) => {
		setCurrentPage(pageNum);
	}

	const handleRowsPerPageChange = (rowsPerPage: number) => {
		setCurrentPage(0);
		setRowsPerPage(rowsPerPage);
	}

	const handleHeaderCheck = () => {
		const data = tabValue === 'feed' ? feedingData.GetFeedingData.data
			: tabValue === 'runoff'
			? runoffData.GetRunoffData.data : vitalData.GetVitalData.data;
		setSelectedRows(data.length === selectedRows.length ? [] : data.map(d => d.id));
	}
	const handleRowCheck = (id: string) => {
		const idx = selectedRows.indexOf(id);
		setSelectedRows( idx > -1 ? selectedRows.filter(r => r !== id) : [id, ...selectedRows]);
	}

	const handleDeleteDialogOk = () => {
		setDialogOpen(false);
		let deleteMutation;
		let refetchFn: any;
		switch (tabValue) {
			case "runoff":
				deleteMutation = deleteRunoffData;
				refetchFn = runoffDataRefetch;
				break;
			case "feed":
				deleteMutation = deleteFeedingData;
				refetchFn = feedingDataRefetch;
				break;
			case "vital":
				deleteMutation = deleteVitalData;
				refetchFn = vitalDataRefetch;
				break;
		}

		deleteMutation({ variables: { ids: idsToDelete.current }  })
			.then(value => {
				setSelectedRows(selectedRows.filter(r => idsToDelete.current.indexOf(r) === -1));
				refetchFn();
				setDialogOpen(false);
			})
			.catch(reason => {
				showErrorDialog(reason);
			})
			.finally(() => {
			})
	}
	const handleDeleteDialogCancel = () => {
		setDialogOpen(false);
	}

	const getTableProps = () => {
		switch (tabValue) {
			case "feed":
				return { tableData: feedingData.GetFeedingData.data.map(d => { return { roomId: d.room.id, ...d } }), columns: feedingColumns, totalRecords: feedingData.GetFeedingData.totalRows }
			case "runoff":
				return { tableData: runoffData.GetRunoffData.data.map(d => { return { roomId: d.room.id, strainId: d.strain.id, ...d } }), columns: runoffColumns, totalRecords: runoffData.GetRunoffData.totalRows }
			case "vital":
				return { tableData: vitalData.GetVitalData.data.map(d => { return { roomId: d.room.id, strainId: d.strain.id, ...d } }), columns: vitalColumns, totalRecords: vitalData.GetVitalData.totalRows }
		}
	}

	if (!rooms || !strains || runoffDataLoading || feedingDataLoading || vitalDataLoading
			|| (tabValue === 'feed' && !feedingData) || (tabValue === 'runoff' && !runoffData) || (tabValue === 'vital' && !vitalData)) {
		return (
			<DashboardLayout>
				<div>Loading...</div>
			</DashboardLayout>
		)
	}

	if (feedingDataError || runoffDataError || vitalDataError) {
		return (
			<DashboardLayout>
			<MDTypography>
				{feedingDataError ?? runoffDataError}
			</MDTypography>
			</DashboardLayout>
		)
	}

	const showInfoDialog = (content: string, info: boolean) => {
		setDialogColor('info');
		setDialogContent(content);
		setDialogShowOk(!info);
		setDialogOpen(true);
	}

	const showErrorDialog = (content: string) => {
		setDialogColor('error');
		setDialogContent(content);
		setDialogShowOk(false);
		setDialogOpen(true);
	}

	return (
		<DashboardLayout>
			<Dialog fullScreen={fullScreen} open={ dialogOpen } fullWidth={ true }>
				<DialogContent sx={{bgcolor: currentTheme.palette.background.default}}>
					<DialogContentText>
						<MDTypography variant={ 'body1' } style={{whiteSpace: 'pre-wrap'}} color={ dialogColor } component={'span'} my={ '8px' }>
							{ dialogContent }
						</MDTypography>
					</DialogContentText>
				</DialogContent>
				<DialogActions sx={{bgcolor: currentTheme.palette.background.default}}>
					{ dialogShowOk &&
						<MDButton autoFocus onClick={ handleDeleteDialogOk }>
							OK
						</MDButton>
					}
					<MDButton onClick={ handleDeleteDialogCancel }>
						CANCEL
					</MDButton>
				</DialogActions>
			</Dialog>

			<Dialog fullScreen={ fullScreen } open={ editDialogOpen } fullWidth={ true }>
				{/*<DialogTitle>kjkvkj</DialogTitle>*/}
				<DialogContent sx={{bgcolor: currentTheme.palette.background.default}}>
						<AddEditData dataType={ tabValue } rooms={ rooms.GetPlantingRooms }
									 data={ editData }
									 onCancelClick={ handleEditDialogCancel }
									 onSaveClick={ handleEditDialogSave }
									 strains={ strains.GetStrains }></AddEditData>
				</DialogContent>
			</Dialog>

			<MDBox mt={ 3 } mb={5}>
				<Grid container mb={4}>
					<Grid item xs={12} sm={6} lg={2}>
						<AppBar position="static">
							<Tabs orientation={'horizontal'} value={ tabValue } onChange={ handleTabChange }>
								<Tab label="Runoff" value={'runoff'} />
								<Tab label="Feeding" value={'feed'} />
								<Tab label="Vital" value={'vital'} />
							</Tabs>
						</AppBar>
					</Grid>
				</Grid>

				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={ backdropOpen || runoffDataLoading || feedingDataLoading || vitalDataLoading || deleteFeedingLoading || roomsLoading || strainsLoading }
				>
					<CircularProgress color="inherit" />
				</Backdrop>
					<TableContainer sx={ { boxShadow: "none" } }>
						<FeedingTableToolbar title={ tabValue === 'feed' ? 'Feeding' : tabValue === 'runoff' ? 'Runoff' : 'Vital' } numSelected={ selectedRows.length }
											 onDateApplyClick={ handleDateApplyClick }
											 onDeleteClick={ handleToolbarDeleteClick }
											 dateRange={ selectedDateRange }
						/>
						{ (tabValue === 'runoff' && runoffData.GetRunoffData?.data.length === 0)
						|| (tabValue === 'feed' && feedingData.GetFeedingData?.data.length === 0)
						|| (tabValue === 'vital' && vitalData.GetVitalData?.data.length === 0)
							?
								<MDTypography my={ 5 } variant={ 'h3' } sx={{ textAlign: 'center', width: '100%' }}>No Data</MDTypography>
							:
							<DataTable { ...getTableProps()}
									   currentPage={ currentPage } rowsPerPage={ rowsPerPage }
									   checkboxColId={ 'id' }
									   actionCellProps={ {
										   showDots: false, accessor: "actions", actions: [
											   {
												   type: 'icon',
												   tooltip: 'Edit',
												   content: 'edit',
												   callback: handleCellEditClick
											   },
											   {
												   type: 'icon',
												   tooltip: 'Delete',
												   content: 'delete',
												   callback: handleCellDeleteClick
											   }
										   ]
									   } }
									   onPageChange={ handlePageChange }
									   onRowsPerPageChange={ handleRowsPerPageChange }
									   onHeaderCheck={ handleHeaderCheck }
									   onRowCheck={ handleRowCheck }
									   selectedIds={ selectedRows }

							/>
						}
					</TableContainer>

			</MDBox>

		</DashboardLayout>
	)
}
