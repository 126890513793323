import { Form, Formik } from "formik";
import { feedSchema, runoffSchema, vitalSchema } from "./form";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import FormField from "../../components/FormField";
import MenuItem from "@mui/material/MenuItem";
import AppBar from "@mui/material/AppBar";
import { Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import MDButton from "../../components/MDButton";
import React, { useEffect, useState } from "react";
import MDTypography from "../../components/MDTypography";


export type TabValueType = 'runoff' | 'feed' | 'vital';


export interface AddEditDataProps {
	dataType: TabValueType;
	rooms: { id: string, name: string}[];
	strains: { id: string, name: string}[];
	data: any;
	onSaveClick: (values: any, actions: any) => void;
	onCancelClick: () => void;
}




export const AddEditData = (props: AddEditDataProps) => {
	const formikRef = React.useRef(null);
	const [ amPmTabValue, setAmPmTabValue ] = useState(props.data.ampm?.toLowerCase());
	const [ enableSaveBtn, setEnableSaveBtn ] = useState(props.dataType !== 'runoff');


	useEffect(() => {
		// console.log('effect');
		// formikRef.current.initialValues = props.data;
		// formikRef.current.resetForm();
	}, [])

	const handleAmPmTabValue = (event: any, newValue: any) => {
		setEnableSaveBtn(true);
		setAmPmTabValue(newValue);
	}

	const handleSubmit = async (values: any, actions: any) => {
		values.isAm = amPmTabValue === 'am';
		props.onSaveClick(values, actions);
	}

	const handleCancel = () => {
		props.onCancelClick();
	}


	return (
		<>
			<MDTypography mb={1}>{ `Date: ${ new Date(props.data.timestamp).toLocaleDateString() }` }</MDTypography>
		<Formik
			initialValues={ props.data }
			validationSchema={ props.dataType === 'feed' ? feedSchema : props.dataType === 'runoff' ? runoffSchema : vitalSchema }
			onSubmit={ handleSubmit }
			innerRef={ formikRef }
		>
			{({ values, errors, touched, isSubmitting,
				  handleChange, handleBlur, dirty }) => (

				<Form>

					<MDBox pb={ 3 } px={ 3 }>
						<Grid container spacing={ 3 }>
							<Grid item xs={ 12 }>
								<Grid container spacing={ 3 }>
									{ props.dataType === 'vital' ?
										<>
										<Grid item xs={ 12 } sm={ 6 }>
											<FormField label="PPFD" InputLabelProps={ { shrink: true } }
													   value={ values.ppfd }
													   name={ 'ppfd' }
													   type={ 'number' }
													   onChange={ handleChange }
													   error={ errors.ppfd && touched.ppfd }
													   onBlur={ handleBlur }
													   success={ values.ppfd.length > 0 && !errors.ec }
													   inputProps={ {
														   min: '0',
														   max: '2000',
														   step: '1'
													   } }

											/>
										</Grid>
										<Grid item xs={ 12 } sm={ 6 }>
											<FormField label="Brix" InputLabelProps={ { shrink: true } }
													   value={ values.brix }
													   name={ 'brix' }
													   type={ 'number' }
													   onChange={ handleChange }
													   error={ errors.brix && touched.brix }
													   onBlur={ handleBlur }
													   success={ values.brix.length > 0 && !errors.brix }
													   inputProps={ {
														   min: '0',
														   max: '30',
														   step: '0.1'
													   } }

											/>
										</Grid>
										</>
										:
										<>
											<Grid item xs={ 12 } sm={ 4 }>
												<FormField label="EC" InputLabelProps={ { shrink: true } }
														   value={ values.ec }
														   name={ 'ec' }
														   type={ 'number' }
														   onChange={ handleChange }
														   error={ errors.ec && touched.ec }
														   onBlur={ handleBlur }
														   success={ values.ec.length > 0 && !errors.ec }
														   inputProps={ {
															   min: '0',
															   max: '20',
															   step: '0.1'
														   } }

												/>
											</Grid>
											<Grid item xs={ 12 } sm={ 4 }>
												<FormField label="pH" InputLabelProps={ { shrink: true } }
														   value={ values.ph }
														   name={ 'ph' }
														   onChange={ handleChange }
														   error={ errors.ph && touched.ph }
														   onBlur={ handleBlur }
														   success={ values.ph.length > 0 && !errors.ph }
														   type={ 'number' }
														   inputProps={ {
															   min: '0',
															   max: '10',
															   step: '0.1'
														   } }
												/>
											</Grid>
											<Grid item xs={ 12 } sm={ 4 }>
												<FormField label="ml" InputLabelProps={ { shrink: true } }
														   value={ values.ml }
														   name={ 'ml' }
														   onChange={ handleChange }
														   error={ errors.ml && touched.ml }
														   onBlur={ handleBlur }
														   success={ values.ml.length > 0 && !errors.ml }
														   type={ 'number' }
														   inputProps={ {
															   min: '0',
															   max: '2000',
															   step: '5'
														   } }
												/>
											</Grid>
										</>
									}
								</Grid>
							</Grid>
							<Grid item xs={ 12 } sm={ props.dataType === 'feed' ? 6 : 4 }>
								<FormField
									label="Room"
									select
									InputLabelProps={ { shrink: true } }
									// value={ selectedRoom }
									name={ 'roomId' }
									value={ values.roomId }
									error={ errors.roomId && touched.roomId }
									success={ values.roomId !== '-1' && !errors.roomId }
									onChange={ handleChange }
									onBlur={ handleBlur }
									// onChange={ handleRoomSelectChange }
								>
									<MenuItem key={ 'room-select-none' } value={ '-1' } selected={ true }>
										{ '---Select Room---' }
									</MenuItem>
									{ [...props.rooms]
										.sort((a, b) => a.name > b.name ? 1 : -1)
										.map((r) => {
											return <MenuItem key={ r.id } value={ r.id }>{ r.name }</MenuItem>
										})
									}
								</FormField>
							</Grid>
							{ props.dataType === 'feed' ? '' :
								<>
									<Grid item xs={ 12 } sm={ 4 }>
										<FormField
											label="Strain"
											select
											name={ 'strainId' }
											onChange={ handleChange }
											onBlur={ handleBlur }
											InputLabelProps={ { shrink: true } }
											// value={ selectedStrain }
											value={ values.strainId }
											error={ errors.strainId && touched.strainId }
											success={ values.strainId !== '-1' && !errors.strainId }
											// onChange={ handleStrainSelectChange }
										>
											<MenuItem key={ 'strain-select-none' } value={ '-1' }
													  selected={ true }>
												{ '---Select Strain---' }
											</MenuItem>
											{ [...props.strains]
												.sort((a, b) => a.name > b.name ? 1 : -1)
												.map(r => {
													return <MenuItem key={ r.id }
																	 value={ r.id }>{ r.name }</MenuItem>
												}) }
										</FormField>
									</Grid>
									<Grid item xs={ 12 } sm={ 4 }>
										<FormField
											label="Zone"
											select
											name={ 'zone' }
											InputLabelProps={ { shrink: true } }
											// value={ selectedZone }
											value={ values.zone }
											error={ errors.zone && touched.zone }
											success={ values.zone !== -1 && !errors.zone }
											onChange={ handleChange }
											onBlur={ handleBlur }
											// onChange={ handleZoneSelectChange }
										>
											<MenuItem key={ 'zone-select-none' } value={ '-1' }
													  selected={ true }>
												{ '---Select Zone---' }
											</MenuItem>
											{ [ 1, 2, 3, 4 ]
												.map(r => {
													return <MenuItem key={ `zone-${ r }` }
																	 value={ r + '' }>{ `Zone ${ r }` }</MenuItem>
												}) }
										</FormField>
									</Grid>
								</>
							}
							{ props.dataType === 'feed' ?
								<Grid item xs={ 12 } sm={ 6 }>
									<FormField
										label="Watering Number"
										select
										name={ 'wateringNum' }
										InputLabelProps={ { shrink: true } }
										// value={ selectedWateringNum }
										value={ values.wateringNum }
										error={ errors.wateringNum && touched.wateringNum }
										success={ values.wateringNum !== -1 && !errors.wateringNum }
										onChange={ handleChange }
										onBlur={ handleBlur }
										// onChange={ handleWateringNumSelectChange }
									>
										<MenuItem key={ 'watering-num-select-none' } value={ '-1' }
												  selected={ true }>
											{ '---Select Watering Number---' }
										</MenuItem>
										{ [ ...Array(14).keys() ]
											.map(r => {
												return <MenuItem key={ `zone-${ r + 1 }` }
																 value={ `${ r + 1 }` }>{ r + 1 }</MenuItem>
											}) }
									</FormField>
								</Grid>
							 : '' }
							{ props.dataType == 'runoff' ?
								<Grid container justifyContent={ 'center' }
									  alignItems={ 'center' }>
									<Grid item xs={ 12 } sm={ 6 }>
										<MDBox mt={ 2 } mb={ 2 }>
											<AppBar position="static">
												<Tabs orientation={ 'horizontal' } value={ amPmTabValue }
													  onChange={ handleAmPmTabValue }>
													<Tab
														label="AM"
														value={ 'am' }
													/>
													<Tab
														label="PM"
														value={ 'pm' }
													/>
												</Tabs>
											</AppBar>
										</MDBox>
									</Grid>
								</Grid>
							 : '' }
						</Grid>
					</MDBox>
					<Grid item xs={ 12 }>
						<MDBox display="flex" flexDirection={{ xs: "column", sm: "row-reverse" }}>
							<MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
							<MDButton variant="gradient" color="dark" size="small" mx={5} type={ 'reset' } onClick={ handleCancel }>
								cancel
							</MDButton>
							</MDBox>
							<MDButton variant="gradient" color="dark" size="small" mr={13} type={ 'submit' }>
									  {/*// disabled={ !enableSaveBtn && (isSubmitting || Object.keys(errors).length > 0 || !dirty) } type={ 'submit' }>*/}
								save
							</MDButton>
						</MDBox>
					</Grid>

				</Form>
			)}
		</Formik>
			</>
	)

}

