import { Cell, Column, Row, usePagination, useSortBy, useTable } from "react-table";
import * as React from "react";
import { ChangeEvent, useMemo, useState } from "react";
import { Checkbox, Icon, Table, TableBody, TableFooter, TablePagination, TableRow } from "@mui/material";
import MDBox from "../MDBox";
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";
import { IndeterminateCheckBox } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import MDButton from "../MDButton";
import Tooltip from "@mui/material/Tooltip";


export interface DataTableProps {
	// onNextPage: () => void;
	// onPreviousPage: () => void;
	// onLastPage: () => void;
	// onFirstPage: () => void;
	totalRecords: number;
	currentPage: number;
	rowsPerPage: number;
	columns: Array<Column>;
	tableData: Array<any>;
	checkboxColId?: string;
	actionCellProps?: {
		showDots: boolean;
		accessor: string;
		actions: { type: 'icon' | 'button', tooltip?: string, content: string | JSX.Element, callback: (id: string) => void }[]
	};
	onPageChange: (pageNumber: number) => void;
	onRowsPerPageChange: (rowsPerPage: number) => void;
	selectedIds: string[];
	onHeaderCheck: () => void
	onRowCheck: (id: string) => void
}


export const DataTable = (props: DataTableProps) => {
	const columns = useMemo<any>(() => props.columns, [ props.columns ]);
	const data = useMemo<any>(() => props.tableData, [ props.tableData ]);

	const [ selectedIds, setSelectedIds ] = useState([]);

	const tableInstance = useTable(
		{ columns, data, initialState: { pageIndex: 0 },
		manualPagination: true, manualSortBy: true
		},
		useSortBy,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		page,
		pageOptions,
		canPreviousPage,
		canNextPage,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setGlobalFilter,
		state: { pageIndex, pageSize, globalFilter },
	}: any = tableInstance;

	const handleHeaderCheck = () => {
		props.onHeaderCheck();
	}

	const handleRowCheck = (id: string, event: ChangeEvent<HTMLInputElement>) => {
		props.onRowCheck(id);
	}

	const renderRowCheckbox = (id: string, key: string) => {
		return (
			//<>
			<Checkbox key={`checkbox-${key}`} onChange={ handleRowCheck.bind(this, id) } checked={ props.selectedIds.indexOf(id) > -1 } />
			//<span>{id}</span>
			//</>
		)
	}


	const renderHeaderCheckbox = (key: string) => {
		return (
			<Checkbox key={key} onChange={ handleHeaderCheck }
				indeterminateIcon={<IndeterminateCheckBox style={{color: 'grey'}} />}
				checked={rows.length === props.selectedIds.length }
				indeterminate={ props.selectedIds.length > 0 && props.selectedIds.length < rows.length}
			/>
		)
	}

	const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
		props.onPageChange(page);
	}

	const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement> | null) => {
		props.onRowsPerPageChange(parseInt(event.target.value));
	}

	const renderRowActions = (row: Row, key: string) => {
		const getIcon = (content: string | JSX.Element, cb: any, key?: string) => {
			return (
			<IconButton key={key} onClick={ () => cb(row.values[`${props.checkboxColId}`]) }>
				<Icon  color={ 'secondary' }>{ content }</Icon>
			</IconButton>
			)}
		const getButton = (content: string | JSX.Element, cb: any, key?: string) => {
			return (
				<MDButton key={key} onClick={ () => cb(row.values[`${props.checkboxColId}`]) }>{ content }</MDButton>
			)}

		return (
			<MDBox key={`action-${key}`} style={{ display: 'flex', alignItems: 'center' }}>
				{ props.actionCellProps.actions.map((a, idx) =>{
					return a.type === "icon" ?
						(
							a.tooltip ?
								<Tooltip key={`action-${idx}-${key}`} title={ a.tooltip }>{ getIcon(a.content, a.callback) }</Tooltip>
							: getIcon(a.content, a.callback, `action-${idx}-${key}`)
						)
					:
						(
							a.tooltip ?
								<Tooltip key={`action-${idx}-${key}`} title={ a.tooltip }>{ getButton(a.content, a.callback) }</Tooltip>
								: getButton(a.content, a.callback, `action-${idx}-${key}`)
						)
				}) }
			</MDBox>
		)
	}


	const renderCell= (cell: Cell, row: Row, key: string) => {
		switch (cell.column.id) {
			case props.actionCellProps.accessor:
				return renderRowActions(row, key);
			case props.checkboxColId:
				return renderRowCheckbox(cell.value, key);
			default:
				return cell.render('Cell');
		}
	}


	return (
		// <TableContainer sx={{ boxShadow: "none" }}>
		// 	<TableToolbar title={ props.toolbar.title }
		// 				  showDots={false} numSelected={ selectedIds.length }
		// 	/>
		<Table { ...getTableProps() }>
			<MDBox component="thead">
				{headerGroups.map((headerGroup: any, key: any) => (
					<TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column: any, key: any) => {
							// return column.id === props.checkboxColId ?
							// 	renderHeaderCheckbox() :
							return <DataTableHeadCell
								key={key}
								//{/*{...column.getHeaderProps(isSorted && column.getSortByToggleProps())}*/}
								{...column.getHeaderProps()}
								width={column.width ? column.width : "auto"}
								align={column.align ? column.align : "left"}
								//sorted={setSortedValue(column)}
								sorted={ false }
							>
								{/*{column.render("Header")}*/}
								{column.render(column.id === props.checkboxColId ? renderHeaderCheckbox(key) : "Header")}
							</DataTableHeadCell>
						}
						)}
					</TableRow>
				))}
			</MDBox>

			<TableBody {...getTableBodyProps()}>
				{page.map((row: any, key: any) => {
					prepareRow(row);
					return (
						<TableRow key={key} {...row.getRowProps()}>
							{row.cells.map((cell: any, key: any) => (

								<DataTableBodyCell
									key={key}
									noBorder={ false }
									// noBorder={noEndBorder && rows.length - 1 === key}
									align={cell.column.align ? cell.column.align : "left"}
									{...cell.getCellProps()}
								>
									{/*{ cell.render("Cell") }*/}
									{ renderCell(cell, row, key) }
									{/*{cell.render(cell.column.id === props.checkboxColId ? renderRowCheckbox(cell.value) : "Cell")}*/}
								</DataTableBodyCell>
							))}
						</TableRow>

					);
				})}
					{ tableInstance.state.pageSize > page.length &&
						<TableRow style={{ height: 50 * (tableInstance.state.pageSize - page.length) }}></TableRow>
					}
			</TableBody>

			<TableFooter>
				<TableRow>
				<TablePagination count={ props.totalRecords } page={ props.currentPage } rowsPerPage={ props.rowsPerPage }
								 onPageChange={ handlePageChange }
				onRowsPerPageChange={ handleRowsPerPageChange } showFirstButton showLastButton/>
				</TableRow>
			</TableFooter>
		</Table>
		//{/*</TableContainer>*/}
	)
}

