const secure = true;

export const getApiUrl = (): string => {
	return `http${secure ? 's' : ''}://${process.env.REACT_APP_API_HOST}`;
}

export const getApiGqlUrl = (): string => {
	return `${getApiUrl()}/${process.env.REACT_APP_GQL_PATH}`;
}

export const getWsUrl = (): string => {
	return `ws${secure ? 's' : ''}://${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_WS_PATH}`;
}



export const sleep = (ms: any) =>
	new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
