import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccountNumber: any;
  BigInt: any;
  Byte: any;
  CountryCode: any;
  Cuid: any;
  Currency: any;
  DID: any;
  Date: any;
  DateTime: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IP: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  Latitude: any;
  LocalDate: any;
  LocalEndTime: any;
  LocalTime: any;
  Locale: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  RoutingNumber: any;
  SafeInt: any;
  SemVer: any;
  Time: any;
  TimeZone: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: any;
};

export type FeedData = {
  __typename?: 'FeedData';
  dateStr: Scalars['String'];
  ec: Scalars['String'];
  id: Scalars['String'];
  ml: Scalars['String'];
  ph: Scalars['String'];
  room: Room;
  timestamp: Scalars['Float'];
  user: Scalars['String'];
  wateringNum: Scalars['Int'];
};

export type FeedDataInput = {
  ec: Scalars['Float'];
  id?: InputMaybe<Scalars['String']>;
  ml: Scalars['Float'];
  ph: Scalars['Float'];
  roomId: Scalars['String'];
  wateringNum: Scalars['Int'];
};

export type FeedingPageData = {
  __typename?: 'FeedingPageData';
  data: Array<FeedData>;
  totalRows: Scalars['Int'];
};

export type LoginResult = {
  __typename?: 'LoginResult';
  result: OperationResult;
  user?: Maybe<UserInfo>;
};

export type Mutation = {
  __typename?: 'Mutation';
  CreateRoom?: Maybe<Scalars['Boolean']>;
  DeleteFeedingData?: Maybe<Scalars['Boolean']>;
  DeleteRunoffData?: Maybe<Scalars['Boolean']>;
  DeleteVitalData?: Maybe<Scalars['Boolean']>;
  GoogleCallback?: Maybe<LoginResult>;
  Logout?: Maybe<Scalars['Boolean']>;
  SaveFeedingData?: Maybe<Scalars['Boolean']>;
  SaveRunoffData?: Maybe<Scalars['Boolean']>;
  SaveVitalData?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateRoomArgs = {
  isPlanting: Scalars['Boolean'];
  name: Scalars['String'];
};


export type MutationDeleteFeedingDataArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteRunoffDataArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteVitalDataArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationGoogleCallbackArgs = {
  queryString: Scalars['String'];
};


export type MutationSaveFeedingDataArgs = {
  data: FeedDataInput;
  timestamp: Scalars['Float'];
};


export type MutationSaveRunoffDataArgs = {
  data: RunoffDataInput;
  timestamp: Scalars['Float'];
};


export type MutationSaveVitalDataArgs = {
  data: VitalDataInput;
  timestamp: Scalars['Float'];
};

export type OperationResult = {
  __typename?: 'OperationResult';
  errorMsg: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  ok: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  GetAllRooms: Array<Room>;
  GetFeedingData?: Maybe<FeedingPageData>;
  GetGoogleAuthUrl: Scalars['String'];
  GetNotPlantingRooms: Array<Room>;
  GetPlantingRooms: Array<Room>;
  GetRoom?: Maybe<Room>;
  GetRunoffData?: Maybe<RunoffPageData>;
  GetStrains: Array<Strain>;
  GetUserRoles: Array<UserRole>;
  GetVitalData?: Maybe<VitalPageData>;
  me?: Maybe<UserInfo>;
};


export type QueryGetFeedingDataArgs = {
  fromDate: Scalars['Float'];
  page: Scalars['Int'];
  rows: Scalars['Int'];
  toDate: Scalars['Float'];
};


export type QueryGetRoomArgs = {
  id: Scalars['Int'];
};


export type QueryGetRunoffDataArgs = {
  fromDate: Scalars['Float'];
  page: Scalars['Int'];
  rows: Scalars['Int'];
  toDate: Scalars['Float'];
};


export type QueryGetVitalDataArgs = {
  fromDate: Scalars['Float'];
  page: Scalars['Int'];
  rows: Scalars['Int'];
  toDate: Scalars['Float'];
};

export type Room = {
  __typename?: 'Room';
  id: Scalars['ID'];
  isPlanting: Scalars['Boolean'];
  name: Scalars['String'];
};

export type RunoffData = {
  __typename?: 'RunoffData';
  ampm: Scalars['String'];
  dateStr: Scalars['String'];
  ec: Scalars['String'];
  id: Scalars['String'];
  ml: Scalars['String'];
  ph: Scalars['String'];
  room: Room;
  strain: Strain;
  timestamp: Scalars['Float'];
  user: Scalars['String'];
  zone: Scalars['Int'];
  zoneName: Scalars['String'];
};

export type RunoffDataInput = {
  ec: Scalars['Float'];
  id?: InputMaybe<Scalars['String']>;
  isAm: Scalars['Boolean'];
  ml: Scalars['Float'];
  ph: Scalars['Float'];
  roomId: Scalars['String'];
  strainId: Scalars['String'];
  zone: Scalars['Int'];
};

export type RunoffPageData = {
  __typename?: 'RunoffPageData';
  data: Array<RunoffData>;
  totalRows: Scalars['Int'];
};

export type Strain = {
  __typename?: 'Strain';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  systemEvent: Scalars['String'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  picture: Scalars['String'];
  role: UserRole;
};

export type UserRole = {
  __typename?: 'UserRole';
  _id: Scalars['ID'];
  key: Scalars['String'];
  level: Scalars['Int'];
  name: Scalars['String'];
};

export type VitalData = {
  __typename?: 'VitalData';
  brix: Scalars['String'];
  dateStr: Scalars['String'];
  id: Scalars['String'];
  ppfd: Scalars['String'];
  room: Room;
  strain: Strain;
  timestamp: Scalars['Float'];
  user: Scalars['String'];
  zone: Scalars['Int'];
  zoneName: Scalars['String'];
};

export type VitalDataInput = {
  brix: Scalars['Float'];
  id?: InputMaybe<Scalars['String']>;
  ppfd: Scalars['Float'];
  roomId: Scalars['String'];
  strainId: Scalars['String'];
  zone: Scalars['Int'];
};

export type VitalPageData = {
  __typename?: 'VitalPageData';
  data: Array<VitalData>;
  totalRows: Scalars['Int'];
};

export type SaveRunoffDataMutationVariables = Exact<{
  data: RunoffDataInput;
  timestamp: Scalars['Float'];
}>;


export type SaveRunoffDataMutation = { __typename?: 'Mutation', SaveRunoffData?: boolean | null };

export type SaveFeedingDataMutationVariables = Exact<{
  data: FeedDataInput;
  timestamp: Scalars['Float'];
}>;


export type SaveFeedingDataMutation = { __typename?: 'Mutation', SaveFeedingData?: boolean | null };

export type SaveVitalDataMutationVariables = Exact<{
  data: VitalDataInput;
  timestamp: Scalars['Float'];
}>;


export type SaveVitalDataMutation = { __typename?: 'Mutation', SaveVitalData?: boolean | null };

export type GetRunoffDataQueryVariables = Exact<{
  page: Scalars['Int'];
  rows: Scalars['Int'];
  fromDate: Scalars['Float'];
  toDate: Scalars['Float'];
}>;


export type GetRunoffDataQuery = { __typename?: 'Query', GetRunoffData?: { __typename?: 'RunoffPageData', totalRows: number, data: Array<{ __typename?: 'RunoffData', id: string, ec: string, ph: string, ml: string, zone: number, timestamp: number, user: string, zoneName: string, ampm: string, dateStr: string, room: { __typename?: 'Room', id: string, name: string }, strain: { __typename?: 'Strain', id: string, name: string } }> } | null };

export type GetFeedingDataQueryVariables = Exact<{
  page: Scalars['Int'];
  rows: Scalars['Int'];
  fromDate: Scalars['Float'];
  toDate: Scalars['Float'];
}>;


export type GetFeedingDataQuery = { __typename?: 'Query', GetFeedingData?: { __typename?: 'FeedingPageData', totalRows: number, data: Array<{ __typename?: 'FeedData', id: string, ec: string, ph: string, ml: string, wateringNum: number, timestamp: number, user: string, dateStr: string, room: { __typename?: 'Room', id: string, name: string } }> } | null };

export type GetVitalDataQueryVariables = Exact<{
  page: Scalars['Int'];
  rows: Scalars['Int'];
  fromDate: Scalars['Float'];
  toDate: Scalars['Float'];
}>;


export type GetVitalDataQuery = { __typename?: 'Query', GetVitalData?: { __typename?: 'VitalPageData', totalRows: number, data: Array<{ __typename?: 'VitalData', id: string, ppfd: string, brix: string, timestamp: number, user: string, zoneName: string, zone: number, dateStr: string, strain: { __typename?: 'Strain', id: string, name: string }, room: { __typename?: 'Room', id: string, name: string } }> } | null };

export type DeleteRunoffDataMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteRunoffDataMutation = { __typename?: 'Mutation', DeleteRunoffData?: boolean | null };

export type DeleteFeedingDataMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteFeedingDataMutation = { __typename?: 'Mutation', DeleteFeedingData?: boolean | null };

export type DeleteVitalDataMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteVitalDataMutation = { __typename?: 'Mutation', DeleteVitalData?: boolean | null };

export type GetGoogleAuthUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGoogleAuthUrlQuery = { __typename?: 'Query', GetGoogleAuthUrl: string };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'UserInfo', id: string, name: string, email: string, picture: string, role: { __typename?: 'UserRole', key: string, level: number } } | null };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', Logout?: boolean | null };

export type GoogleCallbackMutationVariables = Exact<{
  queryString: Scalars['String'];
}>;


export type GoogleCallbackMutation = { __typename?: 'Mutation', GoogleCallback?: { __typename?: 'LoginResult', result: { __typename?: 'OperationResult', ok: boolean, errorMsg: string }, user?: { __typename?: 'UserInfo', id: string, email: string, name: string, picture: string, role: { __typename?: 'UserRole', _id: string, name: string, key: string, level: number } } | null } | null };

export type GetAllRoomsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRoomsQuery = { __typename?: 'Query', GetAllRooms: Array<{ __typename?: 'Room', id: string, name: string, isPlanting: boolean }> };

export type GetPlantingRoomsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlantingRoomsQuery = { __typename?: 'Query', GetPlantingRooms: Array<{ __typename?: 'Room', id: string, name: string }> };

export type GetNotPlantingRoomsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotPlantingRoomsQuery = { __typename?: 'Query', GetNotPlantingRooms: Array<{ __typename?: 'Room', id: string, name: string }> };

export type GetStrainsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStrainsQuery = { __typename?: 'Query', GetStrains: Array<{ __typename?: 'Strain', id: string, name: string }> };

export type GetUserRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserRolesQuery = { __typename?: 'Query', GetUserRoles: Array<{ __typename?: 'UserRole', _id: string, name: string, key: string, level: number }> };

export type SystemEventsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SystemEventsSubscription = { __typename?: 'Subscription', systemEvent: string };


export const SaveRunoffDataDocument = gql`
    mutation SaveRunoffData($data: RunoffDataInput!, $timestamp: Float!) {
  SaveRunoffData(data: $data, timestamp: $timestamp)
}
    `;
export type SaveRunoffDataMutationFn = Apollo.MutationFunction<SaveRunoffDataMutation, SaveRunoffDataMutationVariables>;

/**
 * __useSaveRunoffDataMutation__
 *
 * To run a mutation, you first call `useSaveRunoffDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveRunoffDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveRunoffDataMutation, { data, loading, error }] = useSaveRunoffDataMutation({
 *   variables: {
 *      data: // value for 'data'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useSaveRunoffDataMutation(baseOptions?: Apollo.MutationHookOptions<SaveRunoffDataMutation, SaveRunoffDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveRunoffDataMutation, SaveRunoffDataMutationVariables>(SaveRunoffDataDocument, options);
      }
export type SaveRunoffDataMutationHookResult = ReturnType<typeof useSaveRunoffDataMutation>;
export type SaveRunoffDataMutationResult = Apollo.MutationResult<SaveRunoffDataMutation>;
export type SaveRunoffDataMutationOptions = Apollo.BaseMutationOptions<SaveRunoffDataMutation, SaveRunoffDataMutationVariables>;
export const SaveFeedingDataDocument = gql`
    mutation SaveFeedingData($data: FeedDataInput!, $timestamp: Float!) {
  SaveFeedingData(data: $data, timestamp: $timestamp)
}
    `;
export type SaveFeedingDataMutationFn = Apollo.MutationFunction<SaveFeedingDataMutation, SaveFeedingDataMutationVariables>;

/**
 * __useSaveFeedingDataMutation__
 *
 * To run a mutation, you first call `useSaveFeedingDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFeedingDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFeedingDataMutation, { data, loading, error }] = useSaveFeedingDataMutation({
 *   variables: {
 *      data: // value for 'data'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useSaveFeedingDataMutation(baseOptions?: Apollo.MutationHookOptions<SaveFeedingDataMutation, SaveFeedingDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveFeedingDataMutation, SaveFeedingDataMutationVariables>(SaveFeedingDataDocument, options);
      }
export type SaveFeedingDataMutationHookResult = ReturnType<typeof useSaveFeedingDataMutation>;
export type SaveFeedingDataMutationResult = Apollo.MutationResult<SaveFeedingDataMutation>;
export type SaveFeedingDataMutationOptions = Apollo.BaseMutationOptions<SaveFeedingDataMutation, SaveFeedingDataMutationVariables>;
export const SaveVitalDataDocument = gql`
    mutation SaveVitalData($data: VitalDataInput!, $timestamp: Float!) {
  SaveVitalData(data: $data, timestamp: $timestamp)
}
    `;
export type SaveVitalDataMutationFn = Apollo.MutationFunction<SaveVitalDataMutation, SaveVitalDataMutationVariables>;

/**
 * __useSaveVitalDataMutation__
 *
 * To run a mutation, you first call `useSaveVitalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveVitalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveVitalDataMutation, { data, loading, error }] = useSaveVitalDataMutation({
 *   variables: {
 *      data: // value for 'data'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useSaveVitalDataMutation(baseOptions?: Apollo.MutationHookOptions<SaveVitalDataMutation, SaveVitalDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveVitalDataMutation, SaveVitalDataMutationVariables>(SaveVitalDataDocument, options);
      }
export type SaveVitalDataMutationHookResult = ReturnType<typeof useSaveVitalDataMutation>;
export type SaveVitalDataMutationResult = Apollo.MutationResult<SaveVitalDataMutation>;
export type SaveVitalDataMutationOptions = Apollo.BaseMutationOptions<SaveVitalDataMutation, SaveVitalDataMutationVariables>;
export const GetRunoffDataDocument = gql`
    query GetRunoffData($page: Int!, $rows: Int!, $fromDate: Float!, $toDate: Float!) {
  GetRunoffData(page: $page, rows: $rows, fromDate: $fromDate, toDate: $toDate) {
    totalRows
    data {
      id
      ec
      ph
      ml
      zone
      timestamp
      user
      zoneName
      ampm
      dateStr
      room {
        id
        name
      }
      strain {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetRunoffDataQuery__
 *
 * To run a query within a React component, call `useGetRunoffDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunoffDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunoffDataQuery({
 *   variables: {
 *      page: // value for 'page'
 *      rows: // value for 'rows'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useGetRunoffDataQuery(baseOptions: Apollo.QueryHookOptions<GetRunoffDataQuery, GetRunoffDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRunoffDataQuery, GetRunoffDataQueryVariables>(GetRunoffDataDocument, options);
      }
export function useGetRunoffDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRunoffDataQuery, GetRunoffDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRunoffDataQuery, GetRunoffDataQueryVariables>(GetRunoffDataDocument, options);
        }
export type GetRunoffDataQueryHookResult = ReturnType<typeof useGetRunoffDataQuery>;
export type GetRunoffDataLazyQueryHookResult = ReturnType<typeof useGetRunoffDataLazyQuery>;
export type GetRunoffDataQueryResult = Apollo.QueryResult<GetRunoffDataQuery, GetRunoffDataQueryVariables>;
export function refetchGetRunoffDataQuery(variables: GetRunoffDataQueryVariables) {
      return { query: GetRunoffDataDocument, variables: variables }
    }
export const GetFeedingDataDocument = gql`
    query GetFeedingData($page: Int!, $rows: Int!, $fromDate: Float!, $toDate: Float!) {
  GetFeedingData(page: $page, rows: $rows, fromDate: $fromDate, toDate: $toDate) {
    totalRows
    data {
      id
      ec
      ph
      ml
      wateringNum
      timestamp
      user
      dateStr
      room {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetFeedingDataQuery__
 *
 * To run a query within a React component, call `useGetFeedingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedingDataQuery({
 *   variables: {
 *      page: // value for 'page'
 *      rows: // value for 'rows'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useGetFeedingDataQuery(baseOptions: Apollo.QueryHookOptions<GetFeedingDataQuery, GetFeedingDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeedingDataQuery, GetFeedingDataQueryVariables>(GetFeedingDataDocument, options);
      }
export function useGetFeedingDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedingDataQuery, GetFeedingDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeedingDataQuery, GetFeedingDataQueryVariables>(GetFeedingDataDocument, options);
        }
export type GetFeedingDataQueryHookResult = ReturnType<typeof useGetFeedingDataQuery>;
export type GetFeedingDataLazyQueryHookResult = ReturnType<typeof useGetFeedingDataLazyQuery>;
export type GetFeedingDataQueryResult = Apollo.QueryResult<GetFeedingDataQuery, GetFeedingDataQueryVariables>;
export function refetchGetFeedingDataQuery(variables: GetFeedingDataQueryVariables) {
      return { query: GetFeedingDataDocument, variables: variables }
    }
export const GetVitalDataDocument = gql`
    query GetVitalData($page: Int!, $rows: Int!, $fromDate: Float!, $toDate: Float!) {
  GetVitalData(page: $page, rows: $rows, fromDate: $fromDate, toDate: $toDate) {
    totalRows
    data {
      id
      ppfd
      brix
      timestamp
      user
      zoneName
      zone
      strain {
        id
        name
      }
      dateStr
      room {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetVitalDataQuery__
 *
 * To run a query within a React component, call `useGetVitalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVitalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVitalDataQuery({
 *   variables: {
 *      page: // value for 'page'
 *      rows: // value for 'rows'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useGetVitalDataQuery(baseOptions: Apollo.QueryHookOptions<GetVitalDataQuery, GetVitalDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVitalDataQuery, GetVitalDataQueryVariables>(GetVitalDataDocument, options);
      }
export function useGetVitalDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVitalDataQuery, GetVitalDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVitalDataQuery, GetVitalDataQueryVariables>(GetVitalDataDocument, options);
        }
export type GetVitalDataQueryHookResult = ReturnType<typeof useGetVitalDataQuery>;
export type GetVitalDataLazyQueryHookResult = ReturnType<typeof useGetVitalDataLazyQuery>;
export type GetVitalDataQueryResult = Apollo.QueryResult<GetVitalDataQuery, GetVitalDataQueryVariables>;
export function refetchGetVitalDataQuery(variables: GetVitalDataQueryVariables) {
      return { query: GetVitalDataDocument, variables: variables }
    }
export const DeleteRunoffDataDocument = gql`
    mutation DeleteRunoffData($ids: [String!]!) {
  DeleteRunoffData(ids: $ids)
}
    `;
export type DeleteRunoffDataMutationFn = Apollo.MutationFunction<DeleteRunoffDataMutation, DeleteRunoffDataMutationVariables>;

/**
 * __useDeleteRunoffDataMutation__
 *
 * To run a mutation, you first call `useDeleteRunoffDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunoffDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunoffDataMutation, { data, loading, error }] = useDeleteRunoffDataMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteRunoffDataMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRunoffDataMutation, DeleteRunoffDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRunoffDataMutation, DeleteRunoffDataMutationVariables>(DeleteRunoffDataDocument, options);
      }
export type DeleteRunoffDataMutationHookResult = ReturnType<typeof useDeleteRunoffDataMutation>;
export type DeleteRunoffDataMutationResult = Apollo.MutationResult<DeleteRunoffDataMutation>;
export type DeleteRunoffDataMutationOptions = Apollo.BaseMutationOptions<DeleteRunoffDataMutation, DeleteRunoffDataMutationVariables>;
export const DeleteFeedingDataDocument = gql`
    mutation DeleteFeedingData($ids: [String!]!) {
  DeleteFeedingData(ids: $ids)
}
    `;
export type DeleteFeedingDataMutationFn = Apollo.MutationFunction<DeleteFeedingDataMutation, DeleteFeedingDataMutationVariables>;

/**
 * __useDeleteFeedingDataMutation__
 *
 * To run a mutation, you first call `useDeleteFeedingDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedingDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedingDataMutation, { data, loading, error }] = useDeleteFeedingDataMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteFeedingDataMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeedingDataMutation, DeleteFeedingDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeedingDataMutation, DeleteFeedingDataMutationVariables>(DeleteFeedingDataDocument, options);
      }
export type DeleteFeedingDataMutationHookResult = ReturnType<typeof useDeleteFeedingDataMutation>;
export type DeleteFeedingDataMutationResult = Apollo.MutationResult<DeleteFeedingDataMutation>;
export type DeleteFeedingDataMutationOptions = Apollo.BaseMutationOptions<DeleteFeedingDataMutation, DeleteFeedingDataMutationVariables>;
export const DeleteVitalDataDocument = gql`
    mutation DeleteVitalData($ids: [String!]!) {
  DeleteVitalData(ids: $ids)
}
    `;
export type DeleteVitalDataMutationFn = Apollo.MutationFunction<DeleteVitalDataMutation, DeleteVitalDataMutationVariables>;

/**
 * __useDeleteVitalDataMutation__
 *
 * To run a mutation, you first call `useDeleteVitalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVitalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVitalDataMutation, { data, loading, error }] = useDeleteVitalDataMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteVitalDataMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVitalDataMutation, DeleteVitalDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVitalDataMutation, DeleteVitalDataMutationVariables>(DeleteVitalDataDocument, options);
      }
export type DeleteVitalDataMutationHookResult = ReturnType<typeof useDeleteVitalDataMutation>;
export type DeleteVitalDataMutationResult = Apollo.MutationResult<DeleteVitalDataMutation>;
export type DeleteVitalDataMutationOptions = Apollo.BaseMutationOptions<DeleteVitalDataMutation, DeleteVitalDataMutationVariables>;
export const GetGoogleAuthUrlDocument = gql`
    query GetGoogleAuthUrl {
  GetGoogleAuthUrl
}
    `;

/**
 * __useGetGoogleAuthUrlQuery__
 *
 * To run a query within a React component, call `useGetGoogleAuthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleAuthUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleAuthUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGoogleAuthUrlQuery(baseOptions?: Apollo.QueryHookOptions<GetGoogleAuthUrlQuery, GetGoogleAuthUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoogleAuthUrlQuery, GetGoogleAuthUrlQueryVariables>(GetGoogleAuthUrlDocument, options);
      }
export function useGetGoogleAuthUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoogleAuthUrlQuery, GetGoogleAuthUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoogleAuthUrlQuery, GetGoogleAuthUrlQueryVariables>(GetGoogleAuthUrlDocument, options);
        }
export type GetGoogleAuthUrlQueryHookResult = ReturnType<typeof useGetGoogleAuthUrlQuery>;
export type GetGoogleAuthUrlLazyQueryHookResult = ReturnType<typeof useGetGoogleAuthUrlLazyQuery>;
export type GetGoogleAuthUrlQueryResult = Apollo.QueryResult<GetGoogleAuthUrlQuery, GetGoogleAuthUrlQueryVariables>;
export function refetchGetGoogleAuthUrlQuery(variables?: GetGoogleAuthUrlQueryVariables) {
      return { query: GetGoogleAuthUrlDocument, variables: variables }
    }
export const MeDocument = gql`
    query me {
  me {
    id
    name
    email
    picture
    role {
      key
      level
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export function refetchMeQuery(variables?: MeQueryVariables) {
      return { query: MeDocument, variables: variables }
    }
export const LogoutDocument = gql`
    mutation Logout {
  Logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const GoogleCallbackDocument = gql`
    mutation GoogleCallback($queryString: String!) {
  GoogleCallback(queryString: $queryString) {
    result {
      ok
      errorMsg
    }
    user {
      id
      email
      name
      picture
      role {
        _id
        name
        key
        level
      }
    }
  }
}
    `;
export type GoogleCallbackMutationFn = Apollo.MutationFunction<GoogleCallbackMutation, GoogleCallbackMutationVariables>;

/**
 * __useGoogleCallbackMutation__
 *
 * To run a mutation, you first call `useGoogleCallbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleCallbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleCallbackMutation, { data, loading, error }] = useGoogleCallbackMutation({
 *   variables: {
 *      queryString: // value for 'queryString'
 *   },
 * });
 */
export function useGoogleCallbackMutation(baseOptions?: Apollo.MutationHookOptions<GoogleCallbackMutation, GoogleCallbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleCallbackMutation, GoogleCallbackMutationVariables>(GoogleCallbackDocument, options);
      }
export type GoogleCallbackMutationHookResult = ReturnType<typeof useGoogleCallbackMutation>;
export type GoogleCallbackMutationResult = Apollo.MutationResult<GoogleCallbackMutation>;
export type GoogleCallbackMutationOptions = Apollo.BaseMutationOptions<GoogleCallbackMutation, GoogleCallbackMutationVariables>;
export const GetAllRoomsDocument = gql`
    query getAllRooms {
  GetAllRooms {
    id
    name
    isPlanting
  }
}
    `;

/**
 * __useGetAllRoomsQuery__
 *
 * To run a query within a React component, call `useGetAllRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRoomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRoomsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRoomsQuery, GetAllRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRoomsQuery, GetAllRoomsQueryVariables>(GetAllRoomsDocument, options);
      }
export function useGetAllRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRoomsQuery, GetAllRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRoomsQuery, GetAllRoomsQueryVariables>(GetAllRoomsDocument, options);
        }
export type GetAllRoomsQueryHookResult = ReturnType<typeof useGetAllRoomsQuery>;
export type GetAllRoomsLazyQueryHookResult = ReturnType<typeof useGetAllRoomsLazyQuery>;
export type GetAllRoomsQueryResult = Apollo.QueryResult<GetAllRoomsQuery, GetAllRoomsQueryVariables>;
export function refetchGetAllRoomsQuery(variables?: GetAllRoomsQueryVariables) {
      return { query: GetAllRoomsDocument, variables: variables }
    }
export const GetPlantingRoomsDocument = gql`
    query getPlantingRooms {
  GetPlantingRooms {
    id
    name
  }
}
    `;

/**
 * __useGetPlantingRoomsQuery__
 *
 * To run a query within a React component, call `useGetPlantingRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlantingRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlantingRoomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlantingRoomsQuery(baseOptions?: Apollo.QueryHookOptions<GetPlantingRoomsQuery, GetPlantingRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlantingRoomsQuery, GetPlantingRoomsQueryVariables>(GetPlantingRoomsDocument, options);
      }
export function useGetPlantingRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlantingRoomsQuery, GetPlantingRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlantingRoomsQuery, GetPlantingRoomsQueryVariables>(GetPlantingRoomsDocument, options);
        }
export type GetPlantingRoomsQueryHookResult = ReturnType<typeof useGetPlantingRoomsQuery>;
export type GetPlantingRoomsLazyQueryHookResult = ReturnType<typeof useGetPlantingRoomsLazyQuery>;
export type GetPlantingRoomsQueryResult = Apollo.QueryResult<GetPlantingRoomsQuery, GetPlantingRoomsQueryVariables>;
export function refetchGetPlantingRoomsQuery(variables?: GetPlantingRoomsQueryVariables) {
      return { query: GetPlantingRoomsDocument, variables: variables }
    }
export const GetNotPlantingRoomsDocument = gql`
    query getNotPlantingRooms {
  GetNotPlantingRooms {
    id
    name
  }
}
    `;

/**
 * __useGetNotPlantingRoomsQuery__
 *
 * To run a query within a React component, call `useGetNotPlantingRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotPlantingRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotPlantingRoomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotPlantingRoomsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotPlantingRoomsQuery, GetNotPlantingRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotPlantingRoomsQuery, GetNotPlantingRoomsQueryVariables>(GetNotPlantingRoomsDocument, options);
      }
export function useGetNotPlantingRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotPlantingRoomsQuery, GetNotPlantingRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotPlantingRoomsQuery, GetNotPlantingRoomsQueryVariables>(GetNotPlantingRoomsDocument, options);
        }
export type GetNotPlantingRoomsQueryHookResult = ReturnType<typeof useGetNotPlantingRoomsQuery>;
export type GetNotPlantingRoomsLazyQueryHookResult = ReturnType<typeof useGetNotPlantingRoomsLazyQuery>;
export type GetNotPlantingRoomsQueryResult = Apollo.QueryResult<GetNotPlantingRoomsQuery, GetNotPlantingRoomsQueryVariables>;
export function refetchGetNotPlantingRoomsQuery(variables?: GetNotPlantingRoomsQueryVariables) {
      return { query: GetNotPlantingRoomsDocument, variables: variables }
    }
export const GetStrainsDocument = gql`
    query getStrains {
  GetStrains {
    id
    name
  }
}
    `;

/**
 * __useGetStrainsQuery__
 *
 * To run a query within a React component, call `useGetStrainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStrainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStrainsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStrainsQuery(baseOptions?: Apollo.QueryHookOptions<GetStrainsQuery, GetStrainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStrainsQuery, GetStrainsQueryVariables>(GetStrainsDocument, options);
      }
export function useGetStrainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStrainsQuery, GetStrainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStrainsQuery, GetStrainsQueryVariables>(GetStrainsDocument, options);
        }
export type GetStrainsQueryHookResult = ReturnType<typeof useGetStrainsQuery>;
export type GetStrainsLazyQueryHookResult = ReturnType<typeof useGetStrainsLazyQuery>;
export type GetStrainsQueryResult = Apollo.QueryResult<GetStrainsQuery, GetStrainsQueryVariables>;
export function refetchGetStrainsQuery(variables?: GetStrainsQueryVariables) {
      return { query: GetStrainsDocument, variables: variables }
    }
export const GetUserRolesDocument = gql`
    query getUserRoles {
  GetUserRoles {
    _id
    name
    key
    level
  }
}
    `;

/**
 * __useGetUserRolesQuery__
 *
 * To run a query within a React component, call `useGetUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserRolesQuery, GetUserRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(GetUserRolesDocument, options);
      }
export function useGetUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserRolesQuery, GetUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(GetUserRolesDocument, options);
        }
export type GetUserRolesQueryHookResult = ReturnType<typeof useGetUserRolesQuery>;
export type GetUserRolesLazyQueryHookResult = ReturnType<typeof useGetUserRolesLazyQuery>;
export type GetUserRolesQueryResult = Apollo.QueryResult<GetUserRolesQuery, GetUserRolesQueryVariables>;
export function refetchGetUserRolesQuery(variables?: GetUserRolesQueryVariables) {
      return { query: GetUserRolesDocument, variables: variables }
    }
export const SystemEventsDocument = gql`
    subscription systemEvents {
  systemEvent
}
    `;

/**
 * __useSystemEventsSubscription__
 *
 * To run a query within a React component, call `useSystemEventsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSystemEventsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemEventsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSystemEventsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SystemEventsSubscription, SystemEventsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SystemEventsSubscription, SystemEventsSubscriptionVariables>(SystemEventsDocument, options);
      }
export type SystemEventsSubscriptionHookResult = ReturnType<typeof useSystemEventsSubscription>;
export type SystemEventsSubscriptionResult = Apollo.SubscriptionResult<SystemEventsSubscription>;